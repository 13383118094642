SchoolsOverview.css
.schools-overview {
    width: 100%;
}

.schools-comparison-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.8em;
}

.schools-comparison-table th,
.schools-comparison-table td {
    border: 1px solid #ddd;
    padding: 4px;
    text-align: left;
}

.schools-comparison-table th {
    background-color: #f2f2f2;
}

.schools-comparison-table tr:nth-child(even) {
    background-color: #f9f9f9;
}
/* src/pages/VerificationPage.css */
@import url('../assets/AnebaNeue/aneba-neue.css');

.verification-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: 'Aneba Neue', sans-serif;
    background-color: #f5f5f5;
}

.verification-page__container {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.verification-page__title {
    margin-bottom: 20px;
    color: #333;
    text-align: center;
    font-size: 24px;
}

.verification-page__instruction {
    margin-bottom: 30px;
    color: #666;
    text-align: center;
    font-size: 16px;
}

.verification-page__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.verification-page__inputs {
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
    justify-content: center;
    width: 100%;
}

.verification-page__input {
    width: 40px;
    height: 50px;
    text-align: center;
    font-size: 24px;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
}

.verification-page__input:focus {
    border-color: #007bff;
}

.verification-page__button-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.verification-page__button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.verification-page__button:hover {
    background-color: #0056b3;
}

.verification-page__resend-button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.verification-page__resend-button:hover {
    background-color: #f5f5f5;
}

.verification-page__resend-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: #f5f5f5;
}
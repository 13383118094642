/* src/pages/Login.css */
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ececec; /* Consider using a background image or a more subtle color */
  }
  
  .login-card {
    background: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
  }
  
  .login-title {
    text-align: center;
    margin-bottom: 24px;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-group input {
    width: 100%;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensures padding doesn't increase the input's size */
  }
  
  .login-btn {
    width: 100%;
    padding: 10px 0;
    background-color: #1352e2;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
  }
  
  .login-btn:hover {
    background-color: #0a318e;
  }
  
  /* Add this to your Login.css */
.login-footer {
    margin-top: 20px;
    text-align: center;
  }
  .login-footer a {
    color: #007bff;
    text-decoration: none;
  }
  .login-footer a:hover {
    text-decoration: underline;
  }
.education-table-component {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: hidden;
    font-size: 10px;
}

.education-table__neighborhood {
    display: flex;
    align-items: center;
    padding: 2px 4px;
    font-weight: bold;
}

.education-table__content {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
}

.education-table__wrapper {
    flex-grow: 1;
    overflow: hidden;
    padding: 2px;
}

.education-table__section-header {
    background-color: #333;
    color: white;
    padding: 1px 2px;
    font-weight: bold;
    font-size: 8px;
}

.education-table__row {
    display: flex;
    border-bottom: 1px solid #eee;
}

.education-table__header {
    font-weight: bold;
    background-color: #f0f0f0;
}

.education-table__cell {
    flex: 1;
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.education-table__cell:first-child {
    flex: 1.5;
    justify-content: flex-start;
}
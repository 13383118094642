.user-dashboard-section {
    width: 100%;
    min-height: 100vh;
    background-color: #ECECEC;
    padding: 2vw 0;
    display: flex;
    flex-direction: column;
    font-family: 'Aneba Neue', sans-serif;
}

.user-dashboard-content {
    padding: 0 16vw;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-dashboard-content h2 {
    font-size: 3.2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    text-align: center;
}

.user-dashboard-image {
    width: 69%;
    height: auto;
    object-fit: contain;
    margin: 1rem 0 2rem;
}

.dashboard-features {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.dashboard-features li {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: .2rem;
    padding-left: 1.5rem;
    position: relative;
}

.dashboard-features li:before {
    content: "•";
    position: absolute;
    left: 0;
    color: #333;
}

.user-dashboard-video {
    width: 100%;
    height: auto;
    margin: 0rem 0 2rem;
    object-fit: contain;
    background-color: transparent;
    border-radius: 6px;
}

/* For screens at 1440px */
@media screen and (max-width: 1440px) {
    .user-dashboard-content h2 {
        font-size: 2.8rem;
    }

    .dashboard-features li {
        font-size: 1rem;
    }

    .user-dashboard-image {
        width: 65%;
    }

    .user-dashboard-video {
        width: 100%;
    }
}

/* For screens at 768px */
@media screen and (max-width: 768px) {
    .user-dashboard-section {
        min-height: auto;
        padding: 4vw 0;
    }

    .user-dashboard-content {
        padding: 0 8vw;
    }

    .user-dashboard-content h2 {
        font-size: 2.2rem;
        margin-bottom: 1.5rem;
    }

    .dashboard-features li {
        font-size: 0.9rem;
        margin-bottom: 0.8rem;
    }

    .user-dashboard-image {
        width: 85%;
        margin: 1rem 0 1.5rem;
    }

    .user-dashboard-video {
        width: 100%;
        margin: 1rem 0 1.5rem;
    }
}
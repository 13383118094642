@font-face {
    font-family: 'Aneba Neue';
    src: url('AnebaNeue-SemiBolditalic.eot');
    src: local('Aneba Neue SemiBold Italic'), local('AnebaNeue-SemiBolditalic'),
        url('AnebaNeue-SemiBolditalic.eot?#iefix') format('embedded-opentype'),
        url('AnebaNeue-SemiBolditalic.woff2') format('woff2'),
        url('AnebaNeue-SemiBolditalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Aneba Neue';
    src: url('AnebaNeue-Mediumitalic.eot');
    src: local('Aneba Neue Medium Italic'), local('AnebaNeue-Mediumitalic'),
        url('AnebaNeue-Mediumitalic.eot?#iefix') format('embedded-opentype'),
        url('AnebaNeue-Mediumitalic.woff2') format('woff2'),
        url('AnebaNeue-Mediumitalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Aneba Neue';
    src: url('AnebaNeue-Bolditalic.eot');
    src: local('Aneba Neue Bold Italic'), local('AnebaNeue-Bolditalic'),
        url('AnebaNeue-Bolditalic.eot?#iefix') format('embedded-opentype'),
        url('AnebaNeue-Bolditalic.woff2') format('woff2'),
        url('AnebaNeue-Bolditalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Aneba Neue';
    src: url('AnebaNeue-Bold.eot');
    src: local('Aneba Neue Bold'), local('AnebaNeue-Bold'),
        url('AnebaNeue-Bold.eot?#iefix') format('embedded-opentype'),
        url('AnebaNeue-Bold.woff2') format('woff2'),
        url('AnebaNeue-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Aneba Neue';
    src: url('AnebaNeue-Italic.eot');
    src: local('Aneba Neue Italic'), local('AnebaNeue-Italic'),
        url('AnebaNeue-Italic.eot?#iefix') format('embedded-opentype'),
        url('AnebaNeue-Italic.woff2') format('woff2'),
        url('AnebaNeue-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Aneba Neue';
    src: url('AnebaNeue-Medium.eot');
    src: local('Aneba Neue Medium'), local('AnebaNeue-Medium'),
        url('AnebaNeue-Medium.eot?#iefix') format('embedded-opentype'),
        url('AnebaNeue-Medium.woff2') format('woff2'),
        url('AnebaNeue-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Aneba Neue';
    src: url('AnebaNeue-Regular.eot');
    src: local('Aneba Neue'), local('AnebaNeue-Regular'),
        url('AnebaNeue-Regular.eot?#iefix') format('embedded-opentype'),
        url('AnebaNeue-Regular.woff2') format('woff2'),
        url('AnebaNeue-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Aneba Neue';
    src: url('AnebaNeue-UltraLightitalic.eot');
    src: local('Aneba Neue UltraLight Italic'), local('AnebaNeue-UltraLightitalic'),
        url('AnebaNeue-UltraLightitalic.eot?#iefix') format('embedded-opentype'),
        url('AnebaNeue-UltraLightitalic.woff2') format('woff2'),
        url('AnebaNeue-UltraLightitalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Aneba Neue';
    src: url('AnebaNeue-SemiBold.eot');
    src: local('Aneba Neue SemiBold'), local('AnebaNeue-SemiBold'),
        url('AnebaNeue-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('AnebaNeue-SemiBold.woff2') format('woff2'),
        url('AnebaNeue-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Aneba Neue';
    src: url('AnebaNeue-UltraLight.eot');
    src: local('Aneba Neue UltraLight'), local('AnebaNeue-UltraLight'),
        url('AnebaNeue-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('AnebaNeue-UltraLight.woff2') format('woff2'),
        url('AnebaNeue-UltraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}
.sheets-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    background-color: #ECECEC;
}

.sheets-section {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 4vh 2vw;
    box-sizing: border-box;
    gap: 4vw;
}

.image-box {
    width: 28vw;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vh 1vw;
    box-sizing: border-box;
}

.image-container {
    width: 100%;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1vh;
    position: relative;
    overflow: hidden;
    /* Ensures fade stays within container */
}

.sheets-images {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    z-index: 1;
}
.bottom-spacing{
    margin-bottom: 8vh;
}

.fade-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 175px;
    /* Half of the container height */
    background: linear-gradient(to bottom,
            rgba(236, 236, 236, 0) 0%,
            rgba(236, 236, 236, 0.6) 35%,
            rgba(236, 236, 236, 0.9) 65%,
            rgba(236, 236, 236, 1) 100%);
    z-index: 2;
    pointer-events: none;
}

.text-container {
    text-align: left;
    width: 100%;
    position: relative;
    z-index: 3;
}



.image-box h3 {
    font-family: 'Aneba Neue', sans-serif;
    font-size: 1.35rem;
    font-weight: 500;
    margin: 1vh 0 0.75vh 0;
    /* padding-bottom:.25vh; */
}

.image-box p {
    font-family: 'Aneba Neue', sans-serif;
    font-size: .9rem;
    line-height: 1.3;
    font-weight: 400;
    margin: 0;
}

.try-button {
    background-color: #1352E2;
    color: white;
    border: none;
    border-radius: 4px;
    /* padding: 12px 24px; */
    font-family: 'Aneba Neue', sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    /* margin: 2vh 0 4vh 0; */
    width: 10vw;
    /* You can adjust this value */
    height: 4vh;
    transition: background-color 0.3s ease;
    margin-bottom: 10vh;
}

.try-button:hover {
    background-color: #0f42b8;
}

/* Media queries for responsive design */
@media screen and (max-width: 1200px) {
    .image-container {
        height: min(350px, 28vw);
    }

    .fade-overlay {
        height: 50%;
        /* Keep fade at half the container height */
    }

    .image-box {
        width: 32vw;
    }

    .image-box h3 {
        font-size: 2.2vw;
    }

    .image-box p {
        font-size: 1.4vw;
    }

    .try-button {
        width: auto;
        /* You can adjust this value */
        height: auto;
        padding: 6px 12px;
    }
}

@media screen and (max-width: 900px) {
    .image-container {
        height: min(350px, 32vw);
    }

    .image-box {
        width: 45vw;
    }

    .image-box h3 {
        font-size: 3vw;
    }

    .image-box p {
        font-size: 1.6vw;
    }
}

@media screen and (max-width: 600px) {
    .sheets-section {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4vh;
        min-height: 100%;
    }

    .image-box {
        width: 80vw;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .image-container {
        height: min(350px, 45vw);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text-container {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .image-box h3 {
        font-size: 4vw;
        text-align: center;
    }

    .image-box p {
        font-size: 2.5vw;
        text-align: center;
    }

    .sheets-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0 2vw;
        box-sizing: border-box;
    }

    .try-button {
        width: 125px;
        /* Set a fixed width */
        height: 30px;
        /* Set a fixed height */
        padding: 0;
        /* Remove padding */
        margin: 4vh 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
/* // Tutorial.css */
.tutorial-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2000;
}

.tutorial-container {
    width: 31.25vw;
    height: 70.28vh;
    background-color: #ECECEC;
    border-radius: 6px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
}

.tutorial-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem;
}

.tutorial-image-container {
    width: 14.5vw;
    height: 33.43vh;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
}

.tutorial-image-container::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 35%;
    background: linear-gradient(to bottom,
            rgba(236, 236, 236, 0) 0%,
            rgba(236, 236, 236, 1) 100%);
    pointer-events: none;
}

.tutorial-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.tutorial-title {
    color: #000;
    text-align: center;
    font-family: "Aneba Neue";
    font-size: clamp(12px, 1.34vw, 18px);
    /* 25% smaller */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0.5rem 0;
}

.tutorial-text {
    color: #000;
    text-align: center;
    font-family: "Aneba Neue";
    font-size: clamp(9px, 0.89vw, 12px);
    /* 25% smaller */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 80%;
    margin: 0.25rem 0;
}

.tutorial-text--padded {
    padding-top: 0.75rem;
}

.tutorial-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
}

.tutorial-button {
    width: 5.9vw;
    height: 3.7vh;
    border-radius: 6px;
    font-family: "Aneba Neue";
    font-size: clamp(12px, 1.19vw, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    cursor: pointer;
}

.tutorial-button--start {
    background: #D9D9D9;
    color: #1352E2;
}

.tutorial-button--tour {
    background: #1352E2;
    color: white;
}

.tutorial-bubble {
    position: fixed;
    background: #ECECEC;
    border-radius: 6px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    width: 14.38vw;
    height: 11.93vh;
    padding: 1rem;
    box-sizing: border-box;
    z-index: 2001;
}

.tutorial-bubble--searchbar {
    top: 4rem;
    left: 22rem;
}

.tutorial-bubble-text {
    color: #000;
    text-align: center;
    font-family: "Aneba Neue";
    font-size: clamp(9px, 0.89vw, 12px);
    /* 25% smaller */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
}

.tutorial-exit-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: clamp(8px, 0.589vw, 11.314px);
    height: clamp(8px, 0.589vw, 11.314px);
    cursor: pointer;
}

.tutorial-next-icon {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    width: clamp(8px, 0.589vw, 11.314px);
    height: clamp(8px, 0.589vw, 11.314px);
    cursor: pointer;
    transform: rotate(-90deg);
}

.tutorial-bubble-arrow {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ECECEC;
}

.tutorial-highlight-area {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 2000;
}

.tutorial-highlight-area::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, -0.1);
}

.tutorial-highlight-area::after {
    content: '';
    position: absolute;
    top: var(--target-top);
    left: var(--target-left);
    width: var(--target-width);
    height: var(--target-height);
    background: transparent;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.4);
    z-index: 2001;
}

.tutorial-bubble--sidebar {
    position: fixed;
    width: 14.38vw;
    height: 11.93vh;
}

.tutorial-bubble-arrow-left {
    content: '';
    position: absolute;
    top: 50%;
    left: -20px;
    /* Increased to make arrow more visible */
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 20px solid #ECECEC;
}

.tutorial-bubble--statistics {
    position: fixed;
    width: 14.38vw;
    height: 11.93vh;
}

.tutorial-bubble--add-button {
    position: fixed;
    width: 14.38vw;
    height: 11.93vh;
}

.tutorial-bubble--add-button .tutorial-bubble-arrow-left {
    top: 5px;
    /* Adjust to line up with the add button */
    left: -20px;
    transform: none;
    /* Remove the vertical centering transform */
}

.tutorial-bubble--market-page {
    position: fixed;
    width: 14.38vw;
    height: 11.93vh;
}

.tutorial-bubble-arrow-right {
    position: absolute;
    top: 5px;
    /* Match the same top position as the add button arrow */
    right: -20px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid #ECECEC;
}

.tutorial-bubble--print {
    position: fixed;
    width: 14.38vw;
    height: 11.93vh;
}

.tutorial-bubble-arrow-bottom-right {
    position: absolute;
    bottom: -20px;
    right: 20px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 20px solid #ECECEC;
}
.trash-modal-overlay {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2001;
    background-color: rgba(0, 0, 0, 0.5);
}

.trash-modal-container {
    background-color: #ECECEC;
    border-radius: 6px;
    padding: 32px;
    width: 31.25vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.trash-modal-title {
    color: black;
    text-align: center;
    font-family: "Aneba Neue";
    font-size: 1.34vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;
}

.trash-modal-text {
    color: black;
    text-align: center;
    font-family: "Aneba Neue";
    font-size: 0.89vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;
}

.trash-modal-buttons {
    display: flex;
    gap: 16px;
}

.trash-modal-button-delete {
    background: #1352E2;
    color: white;
    border-radius: 6px;
    padding: 8px 24px;
    font-family: "Aneba Neue";
    font-size: 1.19vw;
    border: none;
    cursor: pointer;
}

.trash-modal-button-keep {
    background: #D9D9D9;
    color: #1352E2;
    border-radius: 6px;
    padding: 8px 24px;
    font-family: "Aneba Neue";
    font-size: 1.19vw;
    border: none;
    cursor: pointer;
}

.trash-modal-button-delete:hover {
    background: #0040d0;
}

.trash-modal-button-keep:hover {
    background: #c0c0c0;
}
.tabular-component {
    width: 26.8vw;
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabular-component__table-wrapper {
    width: calc(100% - 0.5vw);
    margin-left: 3vw;
    overflow: hidden;
    border: 1px solid #000;
    border-radius: 6px;
}

.tabular-component__table {
    width: 100%;
    border-collapse: collapse;
}

.tabular-component__table-header {
    display: flex;
    background: #000;
    color: white;
    height: 1.86vh;
}

.tabular-component__header-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    padding: 0 2px;
    text-align: center;
    box-sizing: border-box;
}

.tabular-component__table-body {
    max-height: calc(1.86vh * 12);
    overflow-y: auto;
}

.tabular-component__table-row {
    display: flex;
    height: 1.86vh;
    position: relative;
}

.tabular-component__cell {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    padding: 0 2px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    box-sizing: border-box;
}

.tabular-component__cell:last-child {
    border-right: none;
}

.tabular-component__address-tooltip {
    position: absolute;
    width: 11.6vw;
    min-height: 2.59vh;
    border-radius: 6px;
    background: #ECECEC;
    z-index: 1000;
    padding: 5px;
    font-size: 14px;
    color: black;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    white-space: normal;
    word-break: break-word;
    pointer-events: none;
}

/* Adjust these widths to ensure alignment */
.tabular-component__header-cell:nth-child(1),
.tabular-component__cell:nth-child(1) {
    width: 4.48vw;
    justify-content: flex-start;
    padding-left: 5px;
}

.tabular-component__header-cell:nth-child(2),
.tabular-component__cell:nth-child(2) {
    width: 2.5vw;
}

.tabular-component__header-cell:nth-child(3),
.tabular-component__cell:nth-child(3) {
    width: 2.5vw;
}

.tabular-component__header-cell:nth-child(4),
.tabular-component__cell:nth-child(4),
.tabular-component__header-cell:nth-child(5),
.tabular-component__cell:nth-child(5),
.tabular-component__header-cell:nth-child(6),
.tabular-component__cell:nth-child(6),
.tabular-component__header-cell:nth-child(7),
.tabular-component__cell:nth-child(7),
.tabular-component__header-cell:nth-child(8),
.tabular-component__cell:nth-child(8) {
    width: 3.5vw;
}
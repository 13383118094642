/* Navbar.css */
@import url('../assets/AnebaNeue/aneba-neue.css');

.navbar {
  background-color: transparent;
  padding: 2.2vh 1.6rem;
  /* Scaled down from 2rem */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  font-family: 'Aneba Neue', sans-serif;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: none;
  margin: 0 1.6vh;
  /* Scaled down from 2vh */
}

.navbar-logo {
  display: flex;
  align-items: center;
  padding-top: .75vh;
}

.logo {
  height: 3.778vh;
  /* Scaled down from 40px */
  width: auto;
}

.logoIcon {
  height: 3.2vh;
}

.navbar-links {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  /* Scaled down from 3.125rem */
}

.navbar-links a {
  color: #1352E2;
  text-decoration: none;
  font-size: 0.8rem;
  /* Scaled down from 1rem */
  font-weight: 500;
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: #0E3AAD;
}

.signup-btn {
  background-color: #1352e2;
  color: #FFFFFF !important;
  padding: 0.4rem 0.8rem;
  /* Scaled down from 0.5rem 1rem */
  border-radius: 4px;
  transition: background-color 0.3s ease;
  text-align: center;
  font-size: 0.8rem;
  /* Scaled down from 1rem */
  font-weight: 500 !important;
}

.signup-btn:hover {
  background-color: #0E3AAD;
}

.navbar.account-page {
  background-color: #FFFFFF;
}

.navbar.account-page .navbar-links a {
  color: #000F30;
}

.navbar.account-page .navbar-links a:hover {
  color: #0E3AAD;
}

.navbar.account-page .signup-btn {
  background-color: #0E3AAD;
  color: #FFFFFF !important;
}

.navbar.account-page .signup-btn:hover {
  background-color: #0E3AAD;
}

.hamburger {
  display: none;
  cursor: pointer;
  z-index: 2000;
}

.line {
  width: 20px;
  /* Scaled down from 25px */
  height: 2.4px;
  /* Scaled down from 3px */
  background-color: #000F30;
  margin: 4px;
  /* Scaled down from 5px */
  transition: all 0.3s ease;
}

@media screen and (max-width: 900px) {
  .navbar-links {
    position: fixed;
    top: 0;
    right: -200px;
    /* Scaled down from 250px */
    width: 200px;
    /* Scaled down from 250px */
    height: 100vh;
    background-color: #FFFFFF;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: right 0.3s ease;
  }

  .navbar-links.open {
    right: 0;
    z-index: 1000;
  }

  .hamburger {
    display: block;
    z-index: 2000;
  }

  .navbar.account-page .hamburger .line {
    background-color: #000F30;
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    background-color: #ECECEC;
    padding: 1rem 1.6rem;
  }

  .navbar-container {
    margin: 0;
  }
}
/* src/pages/Login.css */
@import url('../assets/AnebaNeue/aneba-neue.css');

.login-page {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #FFFFFF;
  font-family: 'Aneba Neue', sans-serif;
  padding-top: 1rem;
  box-sizing: border-box;
}

.signup-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.login-card {
  position: relative;
  z-index: 1;
  width: 22rem;
  min-height: 33rem;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Aneba Neue', sans-serif;
  overflow-y: auto;
  margin: 2rem auto;
  background-color: #FFFFFF;
}

.login-logo {
  display: block;
  margin: 0 auto 15px;
  /* Adjust margin as needed */
  max-width: 100px;
  /* Adjust this value as needed */
  /* Adjust the maximum width as needed */
  height: auto;
  /* Maintain aspect ratio */
}

.input-group {
  margin-bottom: 20px;
}

.login-card .input-group input {
  width: 100%;
  padding: 10px;
  border: 2px solid #ECECEC;
  /* Increase border width and use blue color */
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  /* Remove default outline */
  font-family: 'Aneba Neue', sans-serif;
  font-size: 14px;
}

.input-group input:focus {
  box-shadow: 0 0 5px rgba(19, 82, 226, 0.5);
  /* Add blue box-shadow on focus */
}

.login-btn {
  width: 100%;
  padding: 8px 0;
  background-color: #FFFFFF;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Aneba Neue', sans-serif;
}

.login-btn:hover {
  background-color: #FFFFFF;
}

/* Add this to your Login.css */
.login-footer {
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 8px;
  font-size: 14px;
  margin-bottom: 10px;
}

.login-footer span {
  margin-right: 0.5rem;
}

.login-footer a {
  color: #1352e2;
  text-decoration: none;
}

.login-footer a:hover {
  text-decoration: underline;
}

.password-strength-bar {
  width: 100%;
  height: 4px;
  background-color: #ddd;
  margin-top: 2px;
}

.password-strength-bar-fill {
  height: 100%;
  transition: width 0.3s ease-in-out;
}

.input-group {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 10px;
}


.password-strength-text {
  padding-top: 0.31rem;
  padding-bottom: 1.25rem;
}

.mailing-list-option {
  padding-bottom: 1rem;
  margin-top: 0px;
  /* Very small margin */
  text-align: left;
  font-size: 12px;
  /* Smaller font size */
  font-style: italic;
}

.error-message {
  color: #1352e2;
  font-size: 0.8rem;
  margin-top: 0.25rem;
}

.register-footer {
  margin-top: 10px;
  /* Very small margin */
  margin-bottom: 10px;
  text-align: center;
  font-size: 12px;
  /* Smaller font size */
}

.register-footer span {
  font-style: italic;
}

.register-footer a {
  font-weight: 500;
  color: black;
  text-decoration: none;
}

.register-footer a:hover {
  text-decoration: underline;
}
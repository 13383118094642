.research-page-content {
    padding: 0 1vw;
    background-color: #ECECEC;
}

.research-page-content-header {
    padding-top: 5.56vh;
    padding-bottom: 1.5vh;
    font-size: 24px;
    font-weight: 500;
}

.research-page-content__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    /* This will be equivalent to 1.39vh at 1080p resolution */
}
.population-stats-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.population-stats-tabs {
    display: flex;
    border-bottom: 1px solid #ccc;
}

.population-stats-tabs button {
    padding: 8px 12px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.3s;
}

.population-stats-tabs button.active {
    background-color: #f0f0f0;
    border-bottom: 2px solid #0074D9;
}

.population-stats-content {
    flex-grow: 1;
    overflow: hidden;
}
.blog-sidebar {
    background-color: #ececec;
    flex-shrink: 0;
    height: 84.8vh;  /* Already matched */
    margin-left: 3vw;  /* Already matched */
    overflow-y: auto;
    width: 21vw;  /* Already matched */
    border-radius: 6px;
}

.blog-sidebar h2 {
    font-size: 3vh;
    margin-bottom: 2vh;
    display: flex;
    justify-content: center;
    font-weight: 600;
}

.empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    /* height: calc(100% - 5vh); */
}

.empty-state p {
    font-size: 2vh;
    margin-bottom: 2vh;
    text-align: center;
}

.back-to-blog {
    width: calc(100% - 4vw);
    height: 3.7vh;
    border-radius: 6px;
    background: #D9D9D9;
    color: #000;
    border: none;
    cursor: pointer;
    font-family: "Aneba Neue", sans-serif;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: all 0.3s ease;
}

.back-to-blog:hover {
    background-color: #1352E2;
    color: white;
}
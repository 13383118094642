/* Navbar.css */
@import url('../assets/AnebaNeue/aneba-neue.css');

.navbar.dashboard-page.simple-mode {
    background-color: #FFFFFF;
}


.navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: none;
    margin: 0 auto;
    position: relative;
}

.navbar-logo {
    margin-left: .5vw;
}

.navbar-logo {
    width: auto;
    height: 4.778vh;
    width: auto;
    cursor: pointer;
}

.marketoverlays-search-bar {
    flex-grow: 1;
    position: relative;
    display: flex;
    align-items: center;
    min-height: 2.5vh;
    margin-left: 3vw;
}

.marketoverlays-search-input {
    border: none;
    background-color: #ECECEC;
    color: #000;
    min-height: 30px;
    font-family: 'Aneba Neue', sans-serif;
    width: 17vw;
    padding: 0.5vh 3vw 0.5vh 1vw;
    border-radius: 0.56vh;
    outline: none;
}

.mode-buttons-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
  }
  .location-availability-text {
    font-size: 10px;
    font-style: italic;
    color: #666;
    margin-top: 3.5vh;
    text-align: center;
    width: 20vw;
    position: absolute;
}
.marketoverlays-search-input:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
}

.marketoverlays-search-bar form {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 0.56vh;
}

.marketoverlays-search-button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #000;
    font-size: 0.833vw;
    align-items: center;
    padding: 0 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
}

.marketoverlays-search-button svg {
    font-size: 1.2em;
}

.marketoverlays-search-button:disabled {
    cursor: not-allowed;
    opacity: 0.7;
}

.marketoverlays-search-error {
    position: absolute;
    top: 100%;
    left: 0;
    width: 17vw;
    background-color: #fff;
    border: 1px solid #D9D9D9;
    border-radius: 0 0 0.56vh 0.56vh;
    padding: 1vh;
    margin-top: 0.5vh;
    z-index: 3;
}

.marketoverlays-search-error p {
    color: #dc3545;
    font-size: 1.5vh;
    margin: 0;
    font-family: 'Aneba Neue', sans-serif;
}

.marketoverlays-search-results {
    position: absolute;
    top: 100%;
    left: 0;
    width: 17vw;
    background-color: #fff;
    border: 1px solid #D9D9D9;
    border-radius: 0 0 0.56vh 0.56vh;
    padding: 1vh;
    max-height: 30vh;
    overflow-y: auto;
    z-index: 3;
    margin-top: 0.5vh;
}

.marketoverlays-search-results h3 {
    font-size: 1.5vh;
    margin-bottom: 0.5vh;
}

.marketoverlays-search-results ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.marketoverlays-search-results li {
    padding: 0.5vh;
    cursor: pointer;
}

.marketoverlays-search-results li:hover {
    background-color: #f0f0f0;
}

.mode-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D9D9D9;
    border-radius: 0.37vh;
    position: absolute;
    overflow: hidden;
    min-height: 2.593vh;
    width: 22.5vw;
    height: 2vh;
    left: 50%;
    transform: translateX(-50%);
}

.mode-buttons button {
    color: #000;
    background-color: transparent;
    border: none;
    padding: 0.23vh 0.39vw;
    font-size: 1.30vh;
    cursor: pointer;
    position: relative;
    z-index: 1;
    flex: 1;
    white-space: nowrap;
    transition: color 0.3s ease;
}

.mode-buttons:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 33.33%;
    height: 100%;
    background-color: #000;
    transition: transform 0.3s ease;
    z-index: 0;
}


.mode-buttons.simple-active:before {
    transform: translateX(0);
}

.mode-buttons.custom-active:before {
    transform: translateX(100%);
}

.mode-buttons.research-active:before {
    transform: translateX(200%);
}

.mode-buttons button.active {
    color: #ffffff;
}

.exit-button {
    margin-left: 1.04vw;
}

.navigation-buttons {
    display: flex;
    align-items: center;
    margin-left: 1.04vw;
}

/* .navigation-buttons button {
    background-color: #D9D9D9;
    border: none;
    cursor: pointer;
    margin: 0 0.26vw;
    width: 2.60vw;
    height: 4.6vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.37vh 0.52vw;
    border-radius: 0.37vh;
} */

.navigation-buttons button:hover {
    background-color: #c0c0c0;
}

.navigation-buttons img {
    width: 2.6vw;
    height: 4.6vh;
}

.exit-button button {
    background-color: transparent;
    border: none;
    font-size: 1.39vh;
    color: #000;
    cursor: pointer;
}

/* .map-container {
    height: calc(100vh - 6.48vh);
    width: 100%;
    position: relative;
    top: 6.48vh;
} */

.navbar-styling {
    background-color: #1352e2;
    height: 6.85vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
}

.statistics-tab-block {
    position: absolute;
    top: 50%;
    left: -21px;
    transform: translateY(-47%);
    width: 7.58vw;
    /* Adjust the width as needed */
    height: 100vh;
    /* Adjust the height as needed */
    background-color: white;
    /* Change the background color to transparent */
    z-index: 2;
    /* Ensure the block is behind the statistics tab */
    pointer-events: none;
    /* Disable pointer events on the block */
}

.main-sidebar {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    left: 2.60vw;
    top: 50%;
    transform: translateY(-47%);
    width: 4vw;
    height: 82.2vh;
    background-color: #ECECEC;
    z-index: 3;
    padding-top: 1vh;
    padding-left: 0vh;
    padding-bottom: .93vh;
    padding-right: 0vh;
    overflow-y: auto;
    border-radius: 6px;
}

/* Common styles for sidebar icons */
.main-sidebar img {
    margin-top: 0.93vh;
    cursor: pointer;
    opacity: 1;
    transition: background-color 0.3s ease;
    padding: 0.46vh;
    border-radius: 0.37vh;
}

.main-sidebar img.selected {
    background-color: #e0e0e0;
    opacity: 1;
}

.main-sidebar img:hover {
    opacity: 1;
}

/* Individual icon styles */
.algo-icon {
    height: 3.6vh;
    width: auto;
}

.recent-home-sales-icon {
    height: 3.6vh;
    width: auto;
}

.school-icon {
    height: 3vh;
    width: auto;
}

.population-icon {
    height: 3.3vh;
    width: auto;
}

.crime-icon {
    height: 3.6vh;
    width: auto;
}

.income-icon {
    height: 3.4vh;
    width: auto;
}

.dynamic-neighborhood-comparison-icon {
    height: 3.6vh;
    width: auto;
}

.tabular-neighborhood-comparison-icon {
    height: 3.6vh;
    width: auto;
}

.saved-presets-icon {
    height: 3.6vh;
    width: auto;
}
.location-metrics-icon {
    height: 3.2vh;
}


.main-sidebar img.selected {
    background-color: #e0e0e0;
    opacity: 1;
}

.main-sidebar img:hover {
    opacity: 1;
}

.clicked-feature-box {
    position: absolute;
    top: 1.85vh;
    right: 1.04vw;
    background: #ECECEC;
    padding: 0.93vh 0.52vw;
    border-radius: 0.37vh;
    color: black;
    font-size: 1.7vh;
    font-family: 'Aneba Neue', sans-serif;
    z-index: 2;
    width: 29.3vw;
    height: 3.7vh;
    display: flex;
    align-items: center;
}

.clicked-feature-box p {
    margin-right: 1.04vw;
}

.add-button,
.remove-button {
    padding: 0.46vh 0.52vw;
    border-radius: 0.37vh;
    font-size: 1.30vh;
    cursor: pointer;
    margin-left: 0.52vw;
}

.add-button {
    background-color: #e0e0e0;
    color: #000;
}

.added-button {
    background-color: #4caf50;
    color: #fff;
    cursor: default;
}

.remove-button {
    background-color: #f44336;
    color: #fff;
}

.sidebar-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5vh 0;
    width: 100%;
}

.sidebar-item img {
    margin-top: 0;
    margin-bottom: 0vh;
}

.sidebar-label {
    font-family: 'Aneba Neue', sans-serif;
    font-size: 8px;
    color: #666;
    text-align: center;
    /* margin-top: 0.25vh; */
    white-space: nowrap;
    transition: color 0.3s ease;
}

.sidebar-item:hover .sidebar-label {
    color: #333;
}

.sidebar-item img.selected+.sidebar-label {
    color: #333;
    font-weight: 500;
}

/* Mode Selection Button and Dropdown */
.mode-selector-button {
    display: none;
    position: fixed;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1001;
    width: auto;
    background: transparent;
}

.mode-selector-button button {
    background: #ECECEC;
    border: none;
    border-radius: 4px;
    padding: 0.2rem .6rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #1352e2;
    font-weight: 600;
    font-family: 'Aneba Neue', sans-serif;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.mode-selector-dropdown {
    display: none;
    position: fixed;
    top: calc(1rem + 40px);
    left: 50%;
    transform: translateX(-50%);
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 300px;
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
}

.mode-selector-dropdown.mobile-open {
    opacity: 1;
    transform: translate(-50%, 0);
    pointer-events: all;
}

.mode-selector-dropdown .mode-option {
    padding: 0.75rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.mode-option.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    position: relative;
}

.mode-option.disabled::after {
    content: 'Coming Soon to Mobile!';
    position: absolute;
    bottom: 2px;
    left: 1rem;
    font-size: 10px;
    color: #666;
    font-style: italic;
}

.mode-selector-dropdown .mode-option:hover:not(.disabled) {
    background-color: #f5f5f5;
}

.mode-selector-dropdown .mode-option.active {
    background-color: #f0f0f0;
    font-weight: 500;
}

/* Responsive Adjustments */
@media screen and (max-width: 1200px) {
    .mode-buttons {
        display: none;
    }

    .mode-selector-button {
        display: flex;
    }

    .mode-selector-dropdown {
        display: block;
    }

    .marketoverlays-search-bar {
        margin-left: 1rem;
    }

    .marketoverlays-search-input {
        width: 100%;
        max-width: 250px;
    }

    .mobile-menu-overlay {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s ease-in-out;
    }

    .mobile-menu-overlay.active {
        opacity: 1;
        pointer-events: all;
    }
}

@media screen and (max-width: 480px) {
    .marketoverlays-search-input {
        max-width: 200px;
    }

    .mode-selector-dropdown {
        width: 95%;
    }

    .navbar-logo {
        margin-left: 0.5rem;
    }

    .exit-button {
        margin-right: 0.5rem;
    }
}
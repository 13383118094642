.research-page {
    font-family: "Aneba Neue", sans-serif;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: #ECECEC;
}

.research-top-container {
    height: 5vh;
    display: flex;
    align-items: flex-end;
    margin-top: 7.5vh;
    gap: 2vh;
    flex-wrap: nowrap;
    position: relative;
    z-index: 1;
    margin-left: 1.75vw;
    margin-right: 1.75vw;
}

.research-search-container {
    display: flex;
    gap: 2vh;
}

.research-search-bar {
    display: flex;
    align-items: center;
    background-color: #D9D9D9;
    border-radius: 0.56vh;
    padding: 0.5vh 3vw 0.5vh 1vw;
    width: 25vh;
    height: 3.49vh;
    position: relative;
    z-index: 3;
}

.research-search-icon {
    color: #000;
    font-size: 1.8vh;
    margin-right: 1vh;
}

.research-search-input {
    border: none;
    background-color: transparent;
    color: #000;
    font-family: 'Aneba Neue', sans-serif;
    font-size: 1.6vh;
    width: calc(100% - 5vh);
    height: 100%;
    padding: 0 1vh;
    outline: none;
    transition: background-color 0.3s ease;
}

.research-search-input:disabled {
    background-color: #D9D9D9;
    cursor: not-allowed;
    color: #666666;
}


.research-search-bar.error input {
    border-color: #dc3545;
}

.research-add-button {
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0;
    position: absolute;
    right: 1vh;
    top: 50%;
    transform: translateY(-50%);
}

.research-add-button img {
    width: 12px;
    height: 12px;
}

.research-search-input::placeholder {
    font-size: 1.6vh;
}

.research-search-results {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 0 0 1vh 1vh;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 6;
    max-height: 13vh;
    overflow-y: auto;
}

.research-search-result {
    padding: 1vh;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.research-search-result:hover {
    background-color: #f0f8ff;
}

.research-quick-filter-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.research-filter-label {
    font-size: 1.2vh;
    margin-bottom: 0.5vh;
    color: #000;
}

.research-quick-filter-buttons {
    display: flex;
    gap: 0.5vh;
}

.research-quick-filter-button {
    border: none;
    background-color: #D9D9D9;
    color: black;
    padding: 0.5vh 1vh;
    font-size: 1.2vh;
    border-radius: 0.6vh;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    height: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: .475vh;
}

.research-quick-filter-button:hover {
    background-color: #e6f0ff;
}

.research-quick-filter-button.active {
    background-color: #1352e2;
    color: white;
}

.research-date-filter-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.research-date-filter-content {
    display: flex;
    align-items: center;
    gap: 1vh;
}

.research-date-input {
    border: none;
    background-color: #ECECEC;
    color: black;
    padding: 0.25vh 1vh;
    font-size: 1.4vh;
    border-radius: 0.6vh;
    height: 2.5vh;
    width: 10vh;
    text-align: center;
}

.research-date-arrow {
    color: #000;
    font-size: 1.8vh;
}

.research-dropdown-container {
    position: relative;
    width: 25vh;
    z-index: 3;
}

.research-dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #D9D9D9;
    border-radius: 0.56vh;
    height: 3.49vh;
    cursor: pointer;
    padding: 0.5vh 1vw 0.5vh 1vw;
}

.research-dropdown-placeholder {
    border: none;
    background-color: transparent;
    color: #000;
    font-family: 'Aneba Neue', sans-serif;
    font-size: 1.6vh;
    width: calc(100% - 5vh);
    height: 100%;
    padding: 0 1vh;
    display: inline-flex;
    outline: none;
    align-items: center;
    
}

.research-dropdown-icon {
    height: 12px;
    width: 12px;
    color: #000;
    margin-left: 1vh;
}

.research-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 1vh;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 6;
    max-height: 40vh;
    overflow-y: auto;
}

.research-dropdown-item {
    padding: 1vh;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.research-dropdown-item:last-child {
    border-bottom: none;
}

.research-dropdown-item:hover {
    background-color: #e6f0ff;
}

.research-dropdown-item:hover .research-dropdown-item-title,
.research-dropdown-item:hover .research-dropdown-item-subtitle {
    color: #1352e2;
}

.research-dropdown-item-title {
    display: block;
    font-size: 1.6vh;
    font-weight: 500;
    margin-bottom: 0.5vh;
    color: #333;
}

.research-dropdown-item-subtitle {
    display: block;
    font-size: 1.4vh;
    color: #888;
}

.research-selections-labels {
    display: flex;
    justify-content: space-between;
    color: #919191;
    margin-left: 1.75vw;
    margin-right: 1.75vw;
    margin-bottom: -1vh;
}

.research-selections-labels h3 {
    font-size: 1.4vh;
    color: #000;
    font-weight: normal;
    flex: 1;
    padding: 0 1vh;
}

.research-selections-container {
    display: flex;
    /* width: 100%; */
    background-color: #D9D9D9;
    /* padding: 1vh; */
    position: relative;
    z-index: 0;
    border-radius: 0.56vh;
    min-height: 5vh;
    justify-content: center;
    margin-left: 1.75vw;
    margin-right: 1.75vw;
}

.research-selection-section {
    flex: 1;
    padding: 0 1vh;
    display: flex;
    align-items: center;
}

.research-cards-container.four-neighborhoods {
    justify-content: space-around;
    align-content: space-around;
    margin-left: 1.125vw;
    margin-right: 1.125vw;
    gap: 0vw;
}

.research-content-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    height: 100%;
}

.research-default-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 2vh;
}

.research-default-logo {
    width: 4.906vw;
    /* 75px at 1920px width */
    height: 4.564vh;
    /* 38.496px at 1080px height */
}

.research-default-text-container {
    width: 29.583vw;
    /* 568px at 1920px width */
    height: 5.185vh;
    /* 56px at 1080px height */
    display: flex;
    align-items: center;
    justify-content: center;
}

.research-default-text {
    color: #000;
    text-align: center;
    font-family: "Aneba Neue", sans-serif;
    font-size: 1.781vh;
    /* 16px at 1080px height */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.empty-card {
    width: calc(25% - 1vw);
    aspect-ratio: 1.5;
    background-color: transparent;
}

.research-selection-section.neighborhoods-section {
    flex: 0 0 60%;
    /* 60% of the container width */
}

.research-selection-section.data-point-section {
    flex: 0 0 20%;
    /* 20% of the container width */
}

research-selections-labels h3:nth-child(1) {
    flex: 0 0 20%;
}

.research-selections-labels h3:nth-child(2) {
    flex: 0 0 60%;
}

.research-selections-labels h3:nth-child(3) {
    flex: 0 0 20%;
}

.vertical-divider {
    width: .05vw;
    background-color: #000;
    margin: 0.75vh 0;
    align-self: stretch;
    border-radius: .56vh;
}

.neighborhoods-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.clear-selections-button {
    background-color: transparent;
    border: 1px solid #919191;
    border-radius: 0.56vh;
    color: #000;
    font-size: 1.4vh;
    cursor: pointer;
    padding: 0.5vh 1vh;
    font-family: 'Aneba Neue', sans-serif;
    white-space: nowrap;
}

.clear-selections-button:hover {
    color: #1352E2;
}

/* Update this existing class to work with the new layout */
.neighborhood-pills {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5vh;
    flex: 1;
}

.research-selection-item {
    display: inline-flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid #919191;
    border-radius: 0.56vh;
    padding: 0.5vh 1vh 0.5vh 1vh;
    font-size: 1.4vh;
    cursor: pointer;
    position: relative;
    color: #000;
}

.research-selection-item::after {
    content: '';
    position: absolute;
    right: -.65vw;
    top: 0%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background-image: url('../assets/Group 93.svg');
    /* Update this path to match your project structure */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    /* Hide the remove icon by default */
    transition: opacity 0.3s ease;
    /* Add smooth transition for opacity */
}

.research-selection-item:hover::after {
    opacity: 1;
    /* Show the remove icon on hover */
}

.neighborhood-item {
    display: inline-flex;
    align-items: center;
    padding-left: 1vh;
    margin-right: .5vw;
}

.neighborhood-color-circle {
    width: 1.2vh;
    height: 1.2vh;
    border-radius: 50%;
    margin-right: 0.8vh;
    display: inline-block;
}

/* Remove the specific colors for neighborhood items */
.neighborhoods-section .research-selection-item {
    background-color: #D9D9D9;
    color: #000;
}

/* Adjust the remove button color for all items */
.research-selection-item::after {
    filter: none;
    /* Remove the invert filter */
}


.research-cards-container {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* Change this line */
    align-content: flex-start;
    /* Change this line */
    margin-left: 1.455vw;
    margin-right: 1.455vw;
    margin-top: 1.1vh;
    gap: 1vw;
    min-height: 75vh;
}

/* React-datepicker custom styles */
.react-datepicker-wrapper {
    width: auto;
}

.react-datepicker__input-container input {
    border: none;
    background-color: #D9D9D9;
    color: black;
    padding: 0.25vh 1vh;
    font-size: 1.4vh;
    border-radius: 0.6vh;
    height: 2.5vh;
    width: 10vh;
    text-align: center;
}

.react-datepicker__input-container input:focus {
    outline: 2px solid blue;
    outline-offset: -2px;  /* Pulls the outline inward */
}

.city-selection-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.add-city-button {
    background-color: #1352E2;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.add-city-button:hover {
    background-color: #0039cb;
}

.add-city-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.flashing-border-container {
    position: relative;
    display: inline-block;
    /* Add this */
    width: auto;
    /* Add this */
}

.flashing-border-overlay {
    position: absolute;
    top: -2px;
    /* Adjust position to account for border width */
    left: -2px;
    right: -2px;
    bottom: -2px;
    pointer-events: none;
    border: 2px solid #1352E2;
    border-radius: 0.56vh;
    animation: flashBorder 1.5s ease-in-out infinite;
    z-index: 4;
    /* Increase z-index */
    box-shadow: 0 0 8px rgba(19, 82, 226, 0.3);
    /* Add subtle glow */
}

@keyframes flashBorder {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .research-top-container {
        flex-wrap: wrap;
    }

    .research-search-container,
    .research-quick-filter-container,
    .research-date-filter-container,
    .research-dropdown-container {
        width: 100%;
        margin-bottom: 1vh;
    }

    .research-search-bar,
    .research-dropdown {
        width: 100%;
    }

    .research-selections-container {
        flex-direction: column;
    }

    .city-section,
    .neighborhoods-section,
    .data-point-section {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #ddd;
        padding-bottom: 1vh;
        margin-bottom: 1vh;
    }

    .data-point-section {
        border-bottom: none;
    }

    .research-cards-container {
        justify-content: center;
    }

    .research-page-card {
        width: 45%;
    }
}

@media (max-width: 768px) {
    .research-page-card {
        width: 100%;
    }
}
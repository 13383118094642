@import url("../assets/AnebaNeue/aneba-neue.css");

.contact-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #FFFFFF;
}

.contact-section {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  color: #000;
  min-height: calc(100vh - 80px);
  font-family: "Aneba Neue", sans-serif;
  box-sizing: border-box;
  padding-top: 2rem;
}

.contact-content {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 2vh 4vw;
}

.content-wrapper {
  width: 100%;
}

.contact-content h2 {
  color: #000;
  margin-bottom: 2rem;
  line-height: 1;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.065vw;
}

.contact-form {
  width: 100%;
  margin-bottom: 3rem;
}

.form-group-contact {
  margin-bottom: 1rem;
  width: 100%;
  border: none;
}

.form-group-contact label {
  display: block;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  color: #000;
}

.form-group-contact input,
.form-group-contact textarea {
  width: 97.5%;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  background-color: #ECECEC;
  font-family: "Aneba Neue", sans-serif;
  font-size: 0.875rem;
}

.form-group-contact input:focus,
.form-group-contact textarea:focus {
  outline: none;
  border: none;
}

.form-group-contact textarea {
  min-height: 120px;
  resize: vertical;
}

.form-group-contact input::placeholder,
.form-group-contact textarea::placeholder {
  color: #666;
}

.submit-button {
  background-color: #1352E2;
  color: #fff;
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-family: "Aneba Neue", sans-serif;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.submit-button:hover {
  background-color: #0f41b3;
}

/* Version History Styles */
.version-history {
  width: 100%;
}

.version-item {
  margin-bottom: 1rem;
  background-color: #ECECEC;
  border-radius: 4px;
  overflow: hidden;
}

.version-header {
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-family: "Aneba Neue", sans-serif;
  font-size: 0.875rem;
  transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.version-title {
  flex-grow: 1;
}

.version-header.expanded {
  color: #1352E2;
}
.arrow-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    min-width: 16px; /* Prevent shrinking */
    min-height: 16px; /* Prevent shrinking */
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    flex-shrink: 0;
    object-fit: contain;
    vertical-align: middle;
    margin-left: 8px;
  }
  
  .arrow-icon.rotated {
    transform: rotate(90deg);
    filter: brightness(0) saturate(100%) invert(23%) sepia(92%) saturate(1960%) hue-rotate(215deg) brightness(97%) contrast(87%);
  }

.version-content {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0 1rem;
  color: #636363;
  transform: translateY(-10px);
}

.version-content.expanded {
  max-height: 500px;
  opacity: 1;
  padding: 1rem;
  transform: translateY(0);
}

.version-content ul {
  list-style-type: disc;
  margin: 0;
  padding-left: 1.25rem;
}

.version-content li {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  text-align: left;
}

/* Responsive Styles */
@media screen and (max-width: 900px) {
  .contact-content h2 {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 600px) {
  .contact-content {
    padding: 4vh 4vw;
  }

  .contact-content h2 {
    font-size: 2rem;
  }

  .form-group-contact input,
  .form-group-contact textarea {
    font-size: 0.875rem;
  }
}
/* HeroSection.css */
.realanalytica-hero {
  min-height: 100vh;
  width: 100vw;
  background-color: #ECECEC;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: "Aneba Neue", sans-serif;
}

.realanalytica-hero__content-wrapper {
  align-items: center;
  display: flex
;
  flex-direction: column;
  /* justify-content: flex-start; */
  min-height: 100vh;
  /* padding-top: 30vh; */
  transform: translateY(30%);
  transition: all .6s cubic-bezier(.4,0,.2,1);
  /* transition: padding-top .6s cubic-bezier(.4,0,.2,1); */
}

.realanalytica-hero__content-wrapper.shifted {
  transform: translateY(10%);
  /* Final position after animation */
}
.realanalytica-hero__data-container {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 0.6s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.realanalytica-hero__data-container.expanded {
  height: auto;
  opacity: 1;
  min-height: 500px;
  /* Adjust based on your content */
}

.realanalytica-hero__loading-placeholder {
  width: 100%;
  height: 242px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.realanalytica-hero__loading-placeholder.visible {
  opacity: 1;
  transform: translateY(0);
}

.realanalytica-hero__container {
  width: 100%;
  max-width: 1920px;
  padding: 2rem;
}

.realanalytica-hero__content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.realanalytica-hero__text-block {
  max-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.realanalytica-hero__title {
  color: #000;
  text-align: center;
  font-family: "Aneba Neue";
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 70px;
  letter-spacing: 0px;
  margin-bottom: .5rem;
  width: 100%;
  transform: translateY(0);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.realanalytica-hero__title.text-shift-up {
  transform: translateY(-40px);
}

.realanalytica-hero__subtitle {
  color: #919191;
  text-align: center;
  font-family: "Aneba Neue";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: -0.48px;
  margin-bottom: 1.5rem;
  max-width: 600px;
  transform: translateY(0);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.realanalytica-hero__subtitle.text-shift-up {
  transform: translateY(-30px);
}

.realanalytica-hero__search-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  position: relative;
  transform: translateY(0);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1000;
  /* Add high z-index to ensure wrapper stays above */
}

.realanalytica-hero__search-wrapper.search-shift-up {
  transform: translateY(-20px);
}

.realanalytica-hero__search-bar {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.realanalytica-hero__search-input {
  width: 100%;
  height: 32px;
  padding: 0.25rem 1rem 0.25rem 2.25rem;
  border: none;
  border-radius: 6px;
  background-color: #FFFFFF;
  color: #000;
  font-family: 'Aneba Neue', sans-serif;
  font-size: 14px;
  outline: none;
}

.realanalytica-hero__search-input::placeholder {
  color: #666;
  font-family: 'Aneba Neue', sans-serif;
}

.realanalytica-hero__search-icon {
  position: absolute;
  left: 0.75rem;
  color: #000;
  font-size: 14px;
  pointer-events: none;
  z-index: 1;
}

.realanalytica-hero__search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #D9D9D9;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 1001;
  /* Make sure dropdown is above everything */
  margin-top: -1px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Optional: add shadow for better visibility */
}

.realanalytica-hero__search-results ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.realanalytica-hero__search-results li {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Aneba Neue', sans-serif;
  border-bottom: 1px solid #f0f0f0;
  transition: background-color 0.2s ease;
}

.realanalytica-hero__search-results li:last-child {
  border-bottom: none;
}

.realanalytica-hero__search-results li:hover {
  background-color: #f5f5f5;
}

.realanalytica-hero__location-display {
  margin: 1rem 0;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  margin-bottom: -2rem;
  visibility: hidden;
}

.realanalytica-hero__location-display.fade-in {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.realanalytica-hero__data-displays {
  display: grid;
  grid-template-columns: repeat(2, 391px);
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.4s ease-out, transform 0.4s ease-out;
  transition-delay: 0.3s;
  /* Delay until after layout animations */
}

.realanalytica-hero__data-displays.fade-in {
  opacity: 1;
  visibility: visible;
}

.realanalytica-hero__selected-data,
.rankings-container {
  width: 391px;
  height: 242px;
  flex-shrink: 0;
  background-color: white;
  border-radius: 5px;
  padding: 1.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.realanalytica-hero__blurred-container {
  width: 391px;
  height: 242px;
  flex-shrink: 0;
  background-color: white;
  border-radius: 5px;
  padding: 1.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  filter: blur(4px);
  position: relative;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 100%);
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 100%);
}

.realanalytica-hero__blurred-section {
  grid-column: span 2;
  display: grid;
  grid-template-columns: repeat(2, 391px);
  gap: 1rem;
  justify-content: center;
  position: relative;
}

.realanalytica-hero__chart-title {
  font-family: "Aneba Neue", sans-serif;
  font-size: 0.875rem;
  color: #000;
  margin: 0 0 1rem 0;
  font-weight: 500;
}

.realanalytica-hero__chart-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.realanalytica-hero__signup-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background: none;
}

.realanalytica-hero__signup-button {
  background: #1352E2;
  border: none;
  border-radius: 6px;
  padding: 0.75rem 1.5rem;
  color: white;
  font-family: "Aneba Neue", sans-serif;
  font-size: 1rem;
  cursor: pointer;
  filter: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* Subtle drop shadow using button color */
  transition: all 0.3s ease;
  /* Smooth transition for all properties */
}

.realanalytica-hero__signup-button:hover {
  background: #0f42a8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  /* Slightly larger shadow on hover */
}

.realanalytica-hero__location-name {
  color: #000;
  font-family: "Aneba Neue", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  font-style: italic;
  line-height: 1.2;
  margin: 0;
}

.rankings-header {
  margin-bottom: .5rem;
}

.rankings-title {
  color: #666;
  text-align: left;
  font-size: 0.92rem;
  font-weight: 400;
  margin: 0;
}

.ranking-item {
  font-family: "Aneba Neue", sans-serif;
  font-size: 0.875rem;
  color: #333;
  line-height: 1.4;
}

.rankings-footer {
  margin-top: auto;
  font-family: "Aneba Neue", sans-serif;
  font-size: 0.75rem;
  color: #666;
  font-style: italic;
}

.herosection-loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #666;
  font-family: "Aneba Neue", sans-serif;
  font-size: 0.875rem;
}

.herosection-error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #ff4444;
  font-family: "Aneba Neue", sans-serif;
  font-size: 0.875rem;
  text-align: center;
  padding: 1rem;
}

.valuation-header--hero {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.valuation-label--hero {
  color: #666;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 0;
}

.valuation-value--hero {
  font-weight: 500;
  text-align: left;
  font-size: 1rem;
  color: #000;
}

/* Responsive scaling */
@media screen and (max-width: 1920px) {
  .realanalytica-hero {
    min-height: 100vh;
  }

  .realanalytica-hero__title {
    font-size: clamp(32px, 3.33vw, 64px);
    line-height: clamp(38px, 3.65vw, 70px);
  }

  .realanalytica-hero__subtitle {
    font-size: clamp(16px, 1.25vw, 24px);
    line-height: clamp(24px, 1.82vw, 35px);
  }

  .realanalytica-hero__data-displays {
    grid-template-columns: repeat(2, 20.36vw);
  }

  .realanalytica-hero__selected-data,
  .rankings-container,
  .realanalytica-hero__blurred-container {
    width: 20.36vw;
    height: 22.41vh;
  }

  .realanalytica-hero__blurred-section {
    grid-template-columns: repeat(2, 20.36vw);
  }

  .text-shift-up.realanalytica-hero__title {
    transform: translateY(-30px);
  }

  .text-shift-up.realanalytica-hero__subtitle {
    transform: translateY(-20px);
  }
}

/* Mobile responsive styles */
@media screen and (max-width: 768px) {
  .realanalytica-hero {
    align-items: flex-start;
    min-height: 75vh;
  }

  .realanalytica-hero__container {
    padding: 6rem 2rem 2rem;
  }

  .realanalytica-hero__content {
    flex-direction: column;
  }

  .realanalytica-hero__text-block {
    padding: 0 1rem;
    width: 100%;
  }

  .realanalytica-hero__search-bar {
    width: 90%;
    margin: 0 auto;
  }

  .realanalytica-hero__data-displays {
    grid-template-columns: 1fr;
    max-width: 391px;
    margin: 2rem auto;
  }

  .realanalytica-hero__selected-data,
  .rankings-container,
  .realanalytica-hero__blurred-container {
    width: 100%;
    height: 200px;
    margin: 0 auto;
  }

  .realanalytica-hero__blurred-container {
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 60%);
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 60%);
  }
  .realanalytica-hero__signup-button {
    font-size: 0.875rem;
    padding: 0.625rem 1.25rem;
    margin-bottom: 5vh;
  }

  .realanalytica-hero__signup-overlay {
    bottom: 2rem;
    top: auto;
    transform: translateX(-50%);
  }

  .realanalytica-hero__blurred-section {
    grid-column: 1;
    grid-template-columns: 1fr;
    width: 95%;
    margin: 0 auto;
  }

  .realanalytica-hero__blurred-container--desktop-only {
    display: none;
  }

  .realanalytica-hero__location-name {
    font-size: 1.25rem;
  }

  .text-shift-up.realanalytica-hero__title {
    transform: translateY(-20px);
  }

  .text-shift-up.realanalytica-hero__subtitle {
    transform: translateY(-15px);
  }

  .search-shift-up.realanalytica-hero__search-wrapper {
    transform: translateY(-10px);
  }
}

/* Small mobile devices */
@media screen and (max-width: 480px) {
  .realanalytica-hero__container {
    padding: 5rem 1rem 1rem;
  }

  .realanalytica-hero__search-bar {
    width: 100%;
  }

  .realanalytica-hero__search-input {
    font-size: 14px;
  }

  .realanalytica-hero__search-icon {
    font-size: 12px;
  }
}
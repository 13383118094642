.admin-dashboard {
    padding: 2rem;
    max-width: 1400px;
    margin: 0 auto;
    padding-top: 3rem;
}

.admin-dashboard__title {
    font-size: 1.875rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

.admin-dashboard__section-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 2rem 0 1rem;
    color: #333;
}

.admin-dashboard__metrics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.admin-metric-card {
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 6px;
    padding: 1.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.admin-metric-card__title {
    color: #666;
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.admin-metric-card__value {
    font-size: 1.5rem;
    font-weight: 600;
    color: #000;
}

.admin-metric-card__list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.admin-metric-card__list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid #ECECEC;
}

.admin-metric-card__list-item:last-child {
    border-bottom: none;
}

.admin-metric-card__list-item-name {
    color: #333;
    font-size: 0.875rem;
}

.admin-metric-card__list-item-value {
    font-weight: 500;
    color: #1352E2;
}

.admin-dashboard__data-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
}

.admin-data-card {
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 6px;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.admin-data-card__title {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #000;
}

.admin-data-card__content {
    min-height: 200px;
}

@media (max-width: 768px) {
    .admin-dashboard {
        padding: 1rem;
        padding-top: 3rem;
    }

    .admin-dashboard__metrics-grid {
        grid-template-columns: 1fr;
    }

    .admin-dashboard__data-grid {
        grid-template-columns: 1fr;
    }
}
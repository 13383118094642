/* src/components/TestimonialSection.css */
@import url('../assets/AnebaNeue/aneba-neue.css');

/* Keep existing base styles */
.testimonial-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 1rem;
  background-color: #ECECEC;
  color: black;
  min-height: 100vh;
  font-family: 'Aneba Neue', sans-serif;
  text-align: center;
  font-weight: 500;
  overflow: hidden;
}

.testimonial-background-image {
  position: absolute;
  width: 130%;
  height: 130%;
  right: 170px;
  bottom: -25%;
  z-index: 2;
}

.testimonial-content {
  position: relative;
  z-index: 3;
  max-width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
  padding-top: 2rem;
}

.client-use-case-title {
  font-size: 3.2rem;
  margin-bottom: 0rem;
  line-height: 1.2;
  font-weight: 500;
  margin-top: 1rem;
}

.client-use-case-subtitle {
  font-size: .9rem;
  margin-bottom: 1rem;
  font-weight: 400;
  margin-top: .2rem;
  margin-bottom: 3rem;
}

/* New styles with specific class names */
.client-use-case-boxes {
  display: flex;
  justify-content: center;
  gap: 2.615vw;
  padding: 0 16.14vw;
  margin-bottom: 2rem;
}

.client-use-case-box {
  width: 20.83vw;
  min-height: 51.85vh;
  /* Change to min-height to allow content to breathe */
  background: white;
  border-radius: 8px;
  overflow: hidden;
}

.client-use-case-image-container {
  width: calc(100% - 2rem);
  /* Subtract padding from both sides */
  height: 20.74vh;
  margin: .75rem auto 0;
  /* Center horizontally and add top spacing */
  overflow: hidden;
}

.client-use-case-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.client-use-case-details {
  padding: 1rem 1rem;
}

.client-use-case-realtor-info {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 0.75rem;
}

.client-use-case-realtor-avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 0.75rem;
}

.client-use-case-realtor-text {
  text-align: left;
}

.client-use-case-realtor-name {
  font-size: clamp(0.875rem, 1.5vw, 1rem);
  font-weight: 400;
  margin: 0;
}


.client-use-case-company-name {
  font-size: clamp(0.875rem, 1.5vw, 1rem);
  font-style: italic;
  font-weight: 400;
  margin: 0;
}

.client-use-case-tools-section {
  margin-top: 1rem;
  text-align: left;
}

.client-use-case-tools-header {
  font-size: clamp(0.875rem, 1.5vw, 1rem);
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.client-use-case-tool-tag {
  width: fit-content;
  /* Change from fixed width to fit content */
  min-width: 5.93vw;
  padding: 0.25rem 1rem;
  height: auto;
  /* Change from fixed height to auto */
  background: #ECECEC;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.client-use-case-tool-text {
  color: #1352E2;
  font-size: clamp(0.875rem, 1.5vw, 1rem);
  text-align: center;
  margin: 0;
  white-space: nowrap;
}

.client-use-case-description-section {
  margin-top: 1rem;
  text-align: left;
}

.client-use-case-description-header {
  font-size: clamp(0.875rem, 1.5vw, 1rem);
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.client-use-case-description-text {
  font-size: clamp(0.875rem, 1.5vw, 1rem);
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}

/* Keep existing styles */
.testimonial-section .testimonial-signup-btn {
    background-color: #1352E2;
    color: white;
    border: none;
    border-radius: 4px;
    /* padding: 12px 24px; */
    font-family: 'Aneba Neue', sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    /* margin: 2vh 0 4vh 0; */
    width: 10vw;
    height: 4vh;
    transition: background-color 0.3s ease;
    margin-bottom: 10vh;
}

.testimonial-section .testimonial-signup-btn:hover {
  background-color: #0E3AAD;
}

.white-band {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
  background-color: white;
  z-index: 1;
}

/* Mobile Breakpoints */
@media (max-width: 768px) {
  .client-use-case-boxes {
    flex-direction: column;
    padding: 0 1.5rem;
    gap: 2rem;
  }

  .client-use-case-box {
    width: 100%;
    min-height: auto;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.6s ease, transform 0.6s ease;
  }

  .client-use-case-box.visible {
    opacity: 1;
    transform: translateY(0);
  }

  .client-use-case-image-container {
    height: 30vh;
    width: 100%;
    margin: 0;
  }

  .testimonial-content {
    max-width: 100%;
    padding-top: 1.5rem;
  }

  .client-use-case-details {
    padding: 1.25rem;
  }

  .client-use-case-tool-tag {
    width: auto;
    min-width: auto;
    padding: 0.5rem 1rem;
  }

  .testimonial-background-image {
    opacity: 0.3;
    width: 200%;
    right: -50%;
  }
}

@media (max-width: 480px) {
  .client-use-case-image-container {
    height: 25vh;
  }

  .client-use-case-boxes {
    padding: 0 1rem;
  }

  .client-use-case-title {
    font-size: 2rem;
  }

  .client-use-case-subtitle {
    font-size: 1rem;
  }
}
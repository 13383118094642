.top-brokerages {
    width: 18vw;
    height: 34.6vh;
    border-radius: 6px;
    background: #ECECEC;
    padding: clamp(0.75rem, 1vw, 1rem);
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.top-brokerages__message {
    color: #000;
    font-family: "Aneba Neue", sans-serif;
    font-size: clamp(12px, 0.9vw, 13px);
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    padding: clamp(0.75rem, 1vw, 1rem);
    text-align: center;
}

.top-brokerages__list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.top-brokerages__list::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.top-brokerages__item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.top-brokerages__rank {
    color: #000;
    font-family: "Aneba Neue", sans-serif;
    font-size: clamp(16px, 1.25vw, 20px);
    font-weight: 400;
    line-height: normal;
    flex-shrink: 0;
}

.top-brokerages__card {
    width: 14.2vw;
    height: clamp(70px, 8.98vh, 90px);
    border-radius: 6px;
    border: 1px solid #919191;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.top-brokerages__card-header {
    height: clamp(20px, 1.85vh, 24px);
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    color: #000;
    font-family: "Aneba Neue", sans-serif;
    font-size: clamp(11px, 0.75vw, 12px);
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid #919191;
}

.top-brokerages__card-content {
    flex-grow: 1;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.top-brokerages__referrals {
    font-family: "Aneba Neue", sans-serif;
    font-size: clamp(12px, 0.9vw, 14px);
    margin-bottom: 0.25rem;
}

.top-brokerages__progress-bar {
    width: 100%;
    height: 0.5rem;
    background-color: #D9D9D9;
    border-radius: 3px;
    overflow: hidden;
}

.top-brokerages__progress {
    height: 100%;
    background: linear-gradient(90deg, #1352E2 0%, #23E0D9 50.52%, #FFE600 100%);
}

.top-brokerages__learn-more {
    margin-top: 0.5rem;
    align-self: flex-end;
    color: #1352E2;
    font-family: "Aneba Neue", sans-serif;
    font-size: clamp(11px, 0.75vw, 12px);
    text-decoration: none;
}

/* Responsive Breakpoints */
@media screen and (max-width: 1024px) {
    .top-brokerages {
        width: 22vw;
    }

    .top-brokerages__card {
        width: 17vw;
    }
}

@media screen and (max-width: 768px) {
    .top-brokerages {
        width: 100%;
        height: auto;
        min-height: clamp(250px, 30vh, 300px);
    }

    .top-brokerages__message {
        font-size: 14px;
        padding: 1rem;
    }

    .top-brokerages__list {
        gap: 0.75rem;
    }

    .top-brokerages__card {
        width: calc(100% - 30px); /* Accounting for rank width and gap */
        height: clamp(80px, 10vh, 100px);
    }

    .top-brokerages__rank {
        width: 24px;
        text-align: center;
    }

    .top-brokerages__card-header {
        height: 28px;
        font-size: 13px;
    }

    .top-brokerages__referrals {
        font-size: 14px;
    }

    .top-brokerages__progress-bar {
        height: 0.625rem;
    }

    .top-brokerages__learn-more {
        font-size: 13px;
        padding: 0.5rem;
    }
}

@media screen and (max-width: 480px) {
    .top-brokerages {
        min-height: clamp(200px, 25vh, 250px);
        padding: 0.75rem;
    }

    .top-brokerages__message {
        font-size: 13px;
        padding: 0.75rem;
    }

    .top-brokerages__list {
        gap: 0.5rem;
    }

    .top-brokerages__card {
        height: clamp(70px, 8vh, 90px);
    }

    .top-brokerages__card-header {
        height: 24px;
        font-size: 12px;
    }

    .top-brokerages__referrals {
        font-size: 12px;
    }

    .top-brokerages__rank {
        font-size: 16px;
        width: 20px;
    }

    .top-brokerages__progress-bar {
        height: 0.5rem;
    }

    .top-brokerages__learn-more {
        font-size: 12px;
        padding: 0.25rem;
    }
}
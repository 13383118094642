/* CrimeTab.css */
.crime-stats-container {
    padding: 12px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-height: 0;
}

.crime-stats-title {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 3px;
    color: #1a1a1a;
    margin-top: 0;
}

.crime-metrics-grid {
    display: flex;
    flex-direction: column;
    margin-bottom: 6px;
}

.percentile-bar-wrapper {
    margin-bottom: 4px;
}

.percentile-bar-header {
    display: flex;
    justify-content: space-between;
}

.percentile-bar-label {
    font-size: 0.75rem;
    font-weight: 500;
    color: #4a4a4a;
    width: 120px;
}

.percentile-bar-value {
    font-size: 0.75rem;
    font-weight: 500;
    color: #4a4a4a;
    width: 60px;
    text-align: right;
    padding-right: 8px;
}

.percentile-bar-container {
    height: 8px;
    background-color: #e5e5e5;
    border-radius: 9999px;
    overflow: hidden;
    display: flex;
}

.percentile-bar-segment {
    transition: width 300ms ease;
}

.percentile-bar-segment-safe {
    background-color: #15803d;
}

.percentile-bar-segment-mostly-safe {
    background-color: #4ade80;
}

.percentile-bar-segment-average {
    background-color: #facc15;
}

.percentile-bar-segment-less-safe {
    background-color: #fb923c;
}

.percentile-bar-segment-unsafe {
    background-color: #ef4444;
}

.percentile-markers-container {
    display: flex;
    justify-content: space-between;
    /* margin: 0px 0 4px 0; */
    padding: 0 8px;
}

.percentile-bar-marker {
    font-size: 0.65rem;
    color: #6b7280;
}

.percentile-bar-comparison {
    font-size: 0.75rem;
    color: #4b5563;
    font-style: italic;
}

.crime-stats-legend {
    margin-top: 12px;
    padding: 8px 8px 2px 8px;
    background-color: #f9fafb;
    border-radius: 6px;
    font-size: 0.7rem;
}

.crime-stats-error {
    padding: 12px;
    color: #6b7280;
    font-size: 0.875rem;
}
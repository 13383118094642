.research-page-content-box {
    width: 31.1vw;
    /* font-family: 'Arial', sans-serif; */
    border-radius: 0.9vh;
    border: 1px solid #ececec;
    box-sizing: border-box;

}

.research-page-content-box--wide {
    width: 47vw;
}

.research-page-content-box__title-bar {
    height: 3.15vh;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1vw;
    border-radius: 6px 6px 0px 0px;
}

.research-page-content-box__title {
    color: white;
    font-weight: 600;
    font-size: 1.8vh;
    margin: 0;
}

.research-page-content-box__main {
    height: 25.65vh;
    background-color: white;
    border-radius: 6px;
}

.research-page-content-box__data-type-selector {
    display: flex;
    align-items: center;
    border: 1px solid #FFF;
    border-radius: 6px;
    overflow: hidden;
}

.research-page-content-box__data-type {
    width: 7.86vw;
    height: 2.4vh;
    color: #FFF;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 2.4vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.research-page-content-box__arrow-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 14px;
    padding: 0 5px;
    height: 100%;
    /* This will make the button fill the height of the selector */
    display: flex;
    align-items: center;
}

.research-page-content-box__arrow-button:hover {
    opacity: 0.8;
}
@import url("../assets/AnebaNeue/aneba-neue.css");

.use-cases-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.use-cases-section {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0;
    color: #000;
    min-height: 100vh;
    font-family: "Aneba Neue", sans-serif;
    box-sizing: border-box;
    overflow: hidden;
}

.use-cases-content {
    width: 60%;
    padding: 6vh 4vw 1.85vh 1.56vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    box-sizing: border-box;
    background-color: #ECECEC;
}

.data-offerings {
    width: 40%;
    padding: 6vh 6vw 1.85vh 0vw;
    background-color: #ffffff;
    margin-bottom: 3vh;
}

.use-cases-content h2 {
    color: #000;
    margin-bottom: 0.5rem;
    line-height: 1;
    font-size: 4.5rem;
    font-style: normal;
    font-weight: 550;
    letter-spacing: -0.065vw;
    margin-block-start: 0.75em;
    margin-block-end: 0.33em;
    margin-left: 1rem;
}

.use-cases-content h3,
.data-offerings h3 {
    color: #000;
    margin-bottom: 0.5rem;
    line-height: 1;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 550;
    letter-spacing: -0.065vw;
    margin-block-start: 0.5em;
    margin-block-end: 0.33em;
    margin-left: 1rem;
}

.use-cases-content p,
.data-offerings p {
    color: #000;
    font-size: 0.9rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
}

.use-case {
    margin-bottom: 2rem;
    margin-left: 1rem;
}

.use-case h4 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.data-offerings ul,
.pain-points ul {
    margin-left: 2rem;
    margin-bottom: 1.5rem;
}

.data-offerings li,
.pain-points li {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}

.pain-points {
    margin-top: 2rem;
}

/* Responsive Styles */
@media screen and (max-width: 1200px) {

    .use-cases-content,
    .data-offerings {
        width: 100%;
        padding: 4vh 6vw 1.85vh;
    }

    .use-cases-section {
        flex-direction: column;
    }
}

@media screen and (max-width: 600px) {
    .use-cases-content h2 {
        font-size: 3rem;
    }

    .use-cases-content h3,
    .data-offerings h3 {
        font-size: 2rem;
    }

    .use-case h4 {
        font-size: 1.1rem;
    }

    .use-cases-content p,
    .data-offerings p,
    .data-offerings li,
    .pain-points li {
        font-size: 0.8rem;
    }
}
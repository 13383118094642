.auto-market-page {
    font-family: "Aneba Neue", sans-serif;
    padding: 20px;
    text-align: center;
}

.auto-market-main {
    max-width: 1200px;
    margin: 0 auto;
}

.auto-market-title {
    margin-bottom: 20px;
    padding-top: 5rem;
    font-size: 1.5rem;
    font-weight: 500;
}

.auto-market-info-boxes {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.auto-market-info-box {
    background-color: #f0f0f0;
    padding: 15px 15px 15px 15px;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    width: 180px;
}

.auto-market-instruction {
    margin-bottom: 20px;
}

.auto-market-search-boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.auto-market-search-box {
    display: flex;
    width: 50%;
    position: relative;
}

.auto-market-search-box input {
    flex-grow: 1;
    border: none;
    background-color: #ECECEC;
    color: #000;
    min-height: 30px;
    font-family: 'Aneba Neue', sans-serif;
    width: 100%;
    padding: 0.5vh 3vw 0.5vh 1vw;
    border-radius: 4px;
    outline: none;
    transition: background-color 0.3s ease;
}

.auto-market-search-box input:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
    color: #666666;
}

.auto-market-search-box input.error {
    border-color: #dc3545;
}

.auto-market-search-box button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #000;
    font-size: 0.833vw;
    padding: 0 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
}

.auto-market-plus-button {
    position: absolute;
    top: 30%;
    transform: translateY(-50%);
    right: 2.5rem !important;
}

.auto-market-arrow-button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background-color: #D9D9D9;
    border-radius: 0 0.56vh 0.56vh 0;
}

.auto-market-search-box button img {
    width: 15px;
    height: 15px;
    display: block;
    position: relative;
}

.auto-market-search-results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #D9D9D9;
    border-top: none;
    z-index: 1000;
    text-align: left;
    border-radius: 0 0 0.56vh 0.56vh;
    overflow: hidden;
}

.auto-market-search-results ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.auto-market-search-results li {
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
}

.auto-market-search-results li:hover {
    background-color: #f0f0f0;
}

.auto-market-map-previews {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.auto-market-map-preview-wrapper {
    position: relative;
}

.minus-button-auto-market {
    position: absolute;
    top: 30%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 15px;
    height: 15px;
    right: 3rem !important;
}

.auto-market-map-preview {
    width: 400px;
    height: 250px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
}

.auto-market-map-preview .map-container {
    width: 100%;
    height: 100%;
}

.auto-market-map-preview p {
    margin: 0;
    padding: 5px;
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 12px;
}

.auto-market-map-preview .map-label {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.8);
    padding: 5px;
    font-size: 12px;
}

.auto-market-statistics-input-container {
    margin-top: 20px;
    text-align: center;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.auto-market-statistics-input-container p {
    font-size: 14px;
    margin-bottom: 10px;
    text-align: left;
}

.auto-market-statistics-input-container textarea {
    width: 100%;
    padding: 10px;
    font-family: "Aneba Neue", sans-serif;
    font-size: 14px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    resize: vertical;
    min-height: 100px;
    background-color: #f0f0f0;
}

.auto-market-submit-statistics {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}

.auto-market-submit-statistics img {
    width: 15px;
    height: 15px;
}

.data-options-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.417vw;
    width: 130px;
    cursor: pointer;
    padding: 10px;
    border-radius: 4px;
    background-color: #F5F5F5;
    color: #1352E2;
    font-family: "Aneba Neue", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: all 0.2s ease-in-out;
    margin: 0 auto;
}

.data-options-toggle:hover {
    background-color: #1352E2;
    color: white;
}

.data-options-arrow {
    width: 0.62vw;
    /* 6.154px/1920 */
    height: 0.826vh;
    /* 10px/1080 */
    transition: transform 0.3s ease;
    transform: rotate(270deg);
}

.data-options-arrow.rotated {
    transform: rotate(0deg);
}

/* When hovering, invert the arrow color to white */
.data-options-toggle:hover .data-options-arrow {
    filter: brightness(0) invert(1);
}

.data-categories-section {
    margin-top: 20px;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}



.data-categories-content {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    transform-origin: top;
}

.data-categories-list {
    width: 60%;
    padding: 10px;
    text-align: left;
    font-size: 0.9em;
}

.data-category {
    margin-bottom: 0.5rem;
    transition: all 0.3s ease;
}

.data-category-title {
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1;
    text-align: left;
}

.data-category-header {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    margin-bottom: 2px;
    min-height: 32px;
}

.category-expand-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    background: none;
    border: none;
    color: #1352E2;
    font-size: 0.75rem;
    padding: 4px 6px;
    margin: 2px 0 0 28px;
    /* Aligns with category items */
    cursor: pointer;
    font-family: "Aneba Neue", sans-serif;
    transition: all 0.2s ease;
    white-space: nowrap;
}

.category-expand-button:hover {
    background-color: rgba(19, 82, 226, 0.1);
    border-radius: 4px;
}

.data-category-expand-arrow {
    width: 10px;
    height: 6px;
    transition: transform 0.3s ease;
}

.data-category-expand-arrow.expanded {
    transform: rotate(180deg);
}

.category-icon {
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    margin-right: 4px;
    /* Specific spacing after icon */
}

.category-name {
    font-size: 15px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.info-icon-container {
    margin-left: auto;
    /* Pushes info icon to the right */
    display: inline-flex;
    align-items: center;
}

.info-icon {
    width: 10px;
    height: 10px;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.info-bubble {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 5px;
    z-index: 9999;
    background-color: #ECECEC;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 12px;
}

.info-content {
    position: relative;
    z-index: 1;
    white-space: normal;
    word-wrap: break-word;
    line-height: 1.4;
}

.info-bubble::before {
    content: '';
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 5px 5px 5px;
    border-style: solid;
    border-color: transparent transparent #333 transparent;
}

.data-category-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 8px;
    color: gray;
    font-size: 13px;
    margin-left: 20px;
    padding-left: 20px;
    cursor: pointer;
    position: relative;
    z-index: 1000;
    transition: background-color 0.3s ease;
}

.data-category-item:hover {
    background-color: rgba(19, 82, 226, 0.05);
}

.data-categories-instruction {
    font-size: 14px;
    margin-bottom: 15px;
    color: #333;
    font-weight: 500;
    transition: margin-bottom 0.3s ease;
}

.data-category.expanded+.data-categories-instruction {
    margin-bottom: 20px;
}

.data-category-items:last-child {
    margin-bottom: 0;
}

.data-category-item::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    border: 1px solid #ccc;
    background-color: transparent;
    transition: all 0.3s ease;
    border-radius: 2px;
}

.data-category-item.selected {
    color: #1352E2;
}

.data-category-item.selected::before {
    background-color: #1352E2;
    border-color: #1352E2;
}

.data-category-item.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    color: #ccc;
}

.data-category-item .info-icon-container {
    display: flex;
    align-items: center;
}

.data-category-item .info-icon {
    margin-bottom: 0;
    z-index: 1000;
}

.data-category-item .info-bubble {
    position: absolute;
    z-index: 1000;
    right: 0;
    top: 100%;
}

.data-category-item.disabled::before {
    background-color: #ccc;
    border-color: #ccc;
}

.data-category-item-name {
    flex-grow: 1;
    margin-right: 8px;
    font-family: "Aneba Neue", sans-serif;
}

.auto-market-category-items-container {
    overflow: hidden;
    transition: height 0.3s ease;
}

.auto-market-category-items-wrapper {
    padding: 2px 0;
}

.data-categories-selected {
    width: 50%;
    margin-left: 2%;
    margin-top: 10px;
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #ECECEC;
    border-radius: 4px;
}



.data-categories-selected-list {
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
}

.data-category-selected-item {
    border: 1px solid #919191;
    padding: 3px 5px 3px 5px;
    margin-bottom: 3px;
    margin-right: 5px;
    border-radius: 2px;
    display: inline-block;
    font-size: 11px;
    color: #919191;
    position: relative;
    margin-top: 5px;
    margin-left: 5px;
}

.data-category-remove-item {
    position: absolute;
    top: 50%;
    right: -7px;
    transform: translateY(-100%);
    width: 14px;
    height: 14px;
    background-image: url("../assets/Group 93.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    padding: 0 !important;
    margin: 0 !important;
    cursor: pointer;
    z-index: 10;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.data-category-selected-item:hover .data-category-remove-item {
    opacity: 1;
}

.data-categories-clear {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: transparent;
    color: #F79999;
    border: 1px solid #F79999;
    padding: 3px 5px;
    cursor: pointer;
    font-size: 12px;
    border-radius: 4px;
}

.data-categories-submit {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
}

.data-categories-submit img {
    width: 15px;
    height: 15px;
    transition: filter 0.3s ease;
}

.data-category-items {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 20px;
}

.data-category.expanded .data-category-items {
    margin-bottom: 8px;
}

.data-category:not(.expanded) .data-category-items {
    margin-bottom: 4px;
}

.auto-market-show-more-button {
    background: none;
    border: none;
    color: #1352E2;
    font-size: 12px;
    padding: 4px 8px;
    cursor: pointer;
    text-align: left;
    margin-top: 4px;
    margin-left: 20px;
    font-weight: 500;
    font-family: "Aneba Neue", sans-serif;
}

.auto-market-show-more-button:hover {
    text-decoration: underline;
}

.auto-market-category-item-enter {
    opacity: 0;
    transform: translateY(-10px);
}

.auto-market-category-item-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.auto-market-category-item-exit {
    opacity: 1;
    transform: translateY(0);
}

.auto-market-category-item-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.generate-market-sheet-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.generate-market-sheet-button {
    font-family: "Aneba Neue", sans-serif;
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
    margin-bottom: 30px;
}

.filename-input {
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: "Aneba Neue", sans-serif;
}

.auto-primary-button {
    background-color: #1352E2;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.auto-secondary-button {
    background-color: #f0f0f0;
    color: #000;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.auto-cancel-button {
    background-color: transparent;
    color: #666;
    border: 1px solid #ddd;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

/* Print Preview Specific Styles */
.print-preview-inline {
    width: 100%;
    max-width: 100vw;
    margin: 0 auto;
    padding: 0;
    /* overflow-x: auto; */
    -webkit-overflow-scrolling: touch;
}

.print-preview-content-inline {
    width: 489.6px;
    margin: 0 auto;
    padding: 0;
}

.print-preview-canvas {
    width: 489.6px;
    height: 633.6px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 30px rgba(0, 0, 0, .5);
    background-color: white;
    border-radius: 4px;
}

.print-preview-scaled-content {
    width: 816px;
    height: 1056px;
    transform: scale(0.6);
    transform-origin: top left;
}

.print-preview-scaler {
    width: 816px;
    height: 1056px;
    margin-top: 30px;
}

.print-preview-actions-inline {
    width: 489.6px;
    margin-top: 20px;
    padding: 15px;
    background-color: transparent;
}

.print-preview-buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.preview-action-button {
    background-color: #ECECEC;
    color: #1352E2;
    border: none;
    padding: 8px 24px;
    border-radius: 4px;
    cursor: pointer;
    font-family: "Aneba Neue", sans-serif;
    font-weight: 400;
    font-size: 14px;
    transition: background-color 0.2s ease;
}

.preview-action-button:hover {
    background-color: #E0E0E0;
}

.summary-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    padding: 0.46vh 0.52vw;
    margin-bottom: 0.46vh;
    border-radius: 3px;
}

/* Responsive Breakpoints */
@media screen and (max-width: 1024px) {
    .auto-market-info-boxes {
        padding: 0 clamp(10px, 2vw, 20px);
    }

    .auto-market-search-box {
        width: clamp(280px, 70%, 600px);
    }

    .print-preview-canvas {
        box-shadow: none;
    }
}

@media screen and (max-width: 768px) {
    .data-category-title {
        padding: 6px;
    }

    .data-category-header {
        padding: 4px 6px;
    }

    .category-expand-button {
        font-size: 0.7rem;
        padding: 2px 4px;
        margin-left: 24px;
    }

    .data-category-item {
        font-size: 12px;
        padding: 8px;
        margin-left: 16px;
    }

    .data-category-expand-arrow {
        width: 8px;
        height: 5px;
    }

    .auto-market-info-boxes {
        flex-direction: column;
        align-items: center;
    }

    .auto-market-info-box {
        width: clamp(280px, 90%, 400px);
    }

    .auto-market-search-box {
        width: clamp(280px, 90%, 400px);
    }

    .auto-market-map-previews {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    /* Print Preview Mobile Adjustments */
    .print-preview-inline {
        margin: 0;
        padding: 10px;
    }

    .print-preview-content-inline {
        min-width: 489.6px;
        /* Maintain minimum width for print functionality */
    }

    .print-preview-canvas {
        margin: 0;
    }

    /* Add horizontal scroll indicators */
    .print-preview-inline::before,
    .print-preview-inline::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 20px;
        pointer-events: none;
    }

    .print-preview-inline::before {
        left: 0;
        background: linear-gradient(to right, rgba(255, 255, 255, 0.8), transparent);
    }

    .print-preview-inline::after {
        right: 0;
        background: linear-gradient(to left, rgba(255, 255, 255, 0.8), transparent);
    }
}

@media screen and (max-width: 480px) {
    .auto-market-title {
        font-size: 1.25rem;
        padding-top: 4rem;
    }

    .auto-market-info-box {
        margin: 0 10px;
    }

    .auto-market-search-box {
        margin: 0 10px;
    }

    .auto-market-search-box input {
        font-size: 14px;
    }

    /* Print Preview Mobile Optimizations */
    .print-preview-inline {
        padding: 5px;
    }

    /* Enhance scroll indicators */
    .print-preview-inline::before,
    .print-preview-inline::after {
        width: 30px;
    }

    .data-category-title {
        width: auto;
        /* Remove fixed width constraint */
        flex: 1;
    }

    .data-category-header {
        padding: 4px 6px;
        gap: 2px;
    }

    .category-expand-button {
        font-size: 11px;
        padding: 2px 4px;
        margin-left: 20px;
    }

    .data-category-expand-arrow {
        width: 8px;
        height: 5px;
    }

    .data-category-item {
        margin-left: 12px;
        padding: 6px;
    }

    .data-category-item-name {
        padding-left: 14px;
    }

    .category-name {
        font-size: 14px;
    }
}

/* Print-specific styles */
@media print {
    .auto-market-page {
        padding: 0;
        margin: 0;
    }

    .print-preview-canvas {
        box-shadow: none;
    }

    .print-preview-inline {
        overflow: visible;
    }

    .recharts-wrapper,
    canvas,
    svg {
        page-break-inside: avoid;
        break-inside: avoid;
        max-width: 100% !important;
    }

    * {
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }
}
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    width: 100vw;
}

.homepage-container {
    width: 100vw;
    max-width: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}
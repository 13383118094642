/* src/pages/Login.css */
.login-page {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #ECECEC;
  font-family: 'Aneba Neue', sans-serif;
  padding-top: 1rem;
  box-sizing: border-box;
}

.login-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.login-card {
  position: relative;
  z-index: 1;
  background: #fff;
  padding: 40px 40px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.login-logo {
  display: block;
  margin: 0 auto 30px;
  max-width: 100px;
  height: auto;
}

.login-title {
  text-align: center;
  margin-bottom: 24px;
}

.input-group {
  margin-bottom: 10px;
  /* Reduced margin between input boxes */
}

.input-group input {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  /* Added rounded edges */
  box-sizing: border-box;
  background-color: #ececec;
  /* Added background color */
}

.login-btn {
  width: 100%;
  padding: 10px 0;
  background-color: #1352e2;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}

.login-btn:hover {
  background-color: #0056b3;
}

/* Add this to your Login.css */
.login-footer {
  margin-top: 10px;
  text-align: center;
}

.login-footer a {
  color: #007bff;
  text-decoration: none;
}

.login-footer a:hover {
  text-decoration: underline;
}

/* Google Button Styles */
.google-btn {
  background-color: #db4437;
  /* Google's brand color */
  margin-top: 10px;
  width: 100%;
  padding: 10px 0;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}

.google-btn:hover {
  background-color: #c1351d;
  /* Darker shade on hover */
}

.google-btn-text {
  padding-left: 40px;
  /* Make space for the icon */
}

.divider-container {
  display: flex;
  align-items: center;
  margin: 15px 0;
  /* Reduced from 20px */
  width: 100%;
}

.divider-line {
  flex: 1;
  height: 1px;
  background-color: #ccc;
}

.divider-text {
  padding: 0 12px;
  color: #666;
  font-size: 14px;
}

.social-login-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.social-login-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

/* This will work with your existing Button styles */
.social-login-button {
  margin-top: 10px;
}

.register-footer {
  margin-top: 10px;
  /* Very small margin */
  text-align: center;
  font-size: 12px;
  /* Smaller font size */
}

.register-footer span {
  font-style: italic;
}

.register-footer a {
  font-weight: bold;
  color: black;
  text-decoration: none;
}

.register-footer a:hover {
  text-decoration: underline;
}

.forgot-password-footer {
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
  /* Smaller font size */
  font-style: italic;
}

.google-footer {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  /* Smaller font size */
}

@media screen and (max-width: 768px) {
  .login-card {
    padding: 30px 30px 15px;
    /* Reduced padding */
  }

  .social-login-content {
    gap: 6px;
  }

  .social-login-icon {
    width: 16px;
    height: 16px;
  }

  .divider-container {
    margin: 10px 0;
    /* Reduced margin */
  }

  .divider-text {
    padding: 0 8px;
    /* Reduced padding */
    font-size: 13px;
  }
}

@media screen and (max-width: 480px) {
  .login-card {
    padding: 25px 20px 10px;
    /* Further reduced padding */
  }

  .divider-container {
    margin: 8px 0;
  }

  .divider-text {
    padding: 0 6px;
    font-size: 12px;
  }
}
@import url('../assets/AnebaNeue/aneba-neue.css');

.user-dashboard {
    max-width: 80vw;
    margin: 0 auto;
    margin-left: 6.7vw;
    margin-top: -100px;
}

.user-dashboard__heading {
    display: flex;
    align-items: center;
    font-size: 2rem;
    margin-bottom: 1px;
}

.user-dashboard__icon {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
}

.user-dashboard__content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.78vw;
}

.user-dashboard__left-column {
    display: flex;
    flex-direction: column;
}

.user-dashboard__watchlist-section {
    flex-grow: 0;
    flex-shrink: 0;
}

.user-dashboard__section-heading {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.user-dashboard__watchlist-container {
    display: flex;
    gap: 0.78vw;
    align-items: center;
    /* Centers items vertically */
}

.user-dashboard__top-brokerages-section {
    flex-shrink: 0;
    margin-left: 1vw;
}

.user-dashboard__explore-create-section {
    position: relative;
    margin-right: 10.18vw;
}

.user-dashboard__visit-other-products-container {
    display: flex;
    gap: 0.78vw;
}

.user-dashboard__featured-data-insights-section {
    flex-shrink: 0;
    margin-left: -9vw;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    z-index: 1001;
}

.modal-container-watchlist {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 2rem;
    border-radius: 5px;
    width: 90%;
    max-width: 48vw;
    z-index: 1001;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-header h2 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 500;
}

.modal-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: #666;
    padding: 0.5rem;
}

.modal-close-btn:hover {
    color: #000;
    transition: all 0.2s ease;
}

.modal-content {
    max-height: 70vh;
    overflow: visible;
    /* Changed from auto to visible */
    position: relative;
    /* Added to contain the search results */
}

.modal-search {
    flex-grow: 1;
    position: relative;
    display: flex;
    align-items: center;
    min-height: 2.5vh;
    margin-bottom: 1rem;
}

.modal-search input {
    border: none;
    background-color: #D9D9D9;
    color: #000;
    min-height: 30px;
    font-family: 'Aneba Neue', sans-serif;
    width: 100%;
    padding: 0.5vh 3vw 0.5vh 1vw;
    border-radius: 5px;
    outline: none;
}

.modal-search input:disabled {
    filter: brightness(80%);
    transition: all 0.2s ease;
}

.modal-search input:enabled {
    filter: brightness(100%);
    transition: all 0.2s ease;
}

.modal-add-section h3 {
    font-weight: 400;
}

.modal-search-icon {
    position: absolute;
    right: 1vw;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #000;
    font-size: 0.833vw;
    padding: 0 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-search-results {
    position: absolute;
    width: 90%;
    background-color: #fff;
    border: 1px solid #D9D9D9;
    border-top: none;
    /* Removed top border to attach to search box */
    border-radius: 0 0 5px 5px;
    max-height: 200px;
    /* Fixed height for results */
    overflow-y: auto;
    z-index: 1002;
    margin-top: -1rem;
    /* Negative margin to close the gap */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding-inline-start: 0;
}

.modal-search-results li {
    padding: 0.75rem 1rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
}

.modal-search-results li:last-child {
    border-bottom: none;
}

.modal-search-results li:hover {
    background-color: #f0f0f0;
    transition: all 0.2s ease;
}

/* Add style for when search has results */
.modal-search.has-results input {
    border-radius: 5px;
}

.modal-edit-text {
    color: gray;
    font-size: 1rem;
    margin-bottom: -1rem;
}

/* empty styles */
.watchlist--empty {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    text-align: center;
    background: #ECECEC;
    border-radius: 5px;
    min-height: 34.6vh;
    width: 18vw;
}

.watchlist__empty-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.watchlist__search-prompt {
    color: #666;
    font-size: 13px;
    line-height: 1.5;
}

.watchlist__add-initial {
    background: #D9D9D9;
    color: #000;
    border: none;
    border-radius: 5px;
    padding: 0.4rem 1rem;
    font-size: 13px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.watchlist__add-initial:hover {
    background: #1352E2;
    color: white;
    transition: all 0.2s ease;
}

.watchlist-add-btn {
    background: transparent;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 0;
    align-self: center;
    position: relative;
}

.watchlist-add-btn::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #E0E0E0;
    opacity: 0;
    transition: opacity 0.2s ease;
    z-index: 1;
}

.watchlist-add-btn:hover::before {
    opacity: 0.3;
}

.watchlist-add-btn img {
    width: 30px;
    height: 30px;
    opacity: 0.8;
    transition: opacity 0.2s ease;
    position: relative;
    z-index: 2;
}

.watchlist--loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    text-align: center;
    background: #ECECEC;
    border-radius: 5px;
    min-height: 34.6vh;
    width: 18vw;
}

.watchlist__loading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.watchlist__loading-message {
    color: #666;
    font-size: 13px;
    line-height: 1.5;
}

.watchlist__loading-spinner {
    width: 24px;
    height: 24px;
    border: 2px solid #1352E2;
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
    margin: 1rem auto 0;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 1024px) {
    .user-dashboard__content {
        display: grid;
    }
}

@media screen and (max-width: 768px) {
    .user-dashboard {
        max-width: 100%;
        margin: 1rem;
        margin-top: 0;
        padding: 0;
    }

    .user-dashboard__content {
        flex-direction: column;
        gap: 2rem;
        display: flex;
    }

    .user-dashboard__left-column,
    .user-dashboard__right-column {
        width: 100%;
    }

    .user-dashboard__watchlist-container {
        flex-direction: column;
        width: 100%;
        gap: 1rem;
    }

    .user-dashboard__explore-create-section {
        margin-right: 0;
        width: 100%;
    }

    .user-dashboard__visit-other-products-container {
        flex-direction: column;
        width: 100%;
        gap: 1rem;
    }

    .user-dashboard__top-brokerages-section {
        margin-left: 0;
        width: 100%;
    }

    .user-dashboard__featured-data-insights-section {
        margin-left: 0;
        width: 100%;
    }

    .watchlist,
    .watchlist--empty {
        width: 100%;
        min-height: 30vh;
    }

    .watchlist-add-btn {
        width: 100%;
        height: 50px;
        background: #ECECEC;
        border-radius: 5px;
        margin-top: 0.5rem;
    }

    .watchlist-add-btn::before {
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    /* Modal adjustments for mobile */
    .modal-container-watchlist {
        width: 95%;
        height: 90vh;
        max-width: none;
        padding: 1rem;
        overflow-y: auto;
        z-index: 1003;
    }

    .modal-search input {
        padding: 0.75rem 2.5rem 0.75rem 1rem;
        font-size: 16px;
        /* Prevent zoom on iOS */
    }

    .modal-search-icon {
        font-size: 1.2rem;
        right: 0.75rem;
    }

    .modal-search-results {
        width: 100%;
    }

    .modal-header h2 {
        font-size: 1.25rem;
    }

    .watchlist--loading {
        width: 100%;
        min-height: 30vh;
    }
}

/* Additional adjustments for smaller phones */
@media screen and (max-width: 480px) {
    .user-dashboard {
        margin: 0.5rem;
    }

    .user-dashboard__heading {
        font-size: 1.5rem;
    }

    .user-dashboard__section-heading {
        font-size: 1rem;
    }

    .watchlist,
    .watchlist--empty {
        min-height: 25vh;
        min-width: 250px;
        max-height: 200px;
    }

    .modal-container-watchlist {
        padding: 1.25rem;
        height: 70vh;
        /* margin: 2rem; */
        width: 20rem;
        /* display: grid; */
        /* justify-content: center; */
    }

    .watchlist--loading {
        min-height: 25vh;
        padding: 1rem;
    }
}
.map-preview {
    margin-top: 20px;
    width: 400px;
    height: 250px;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
}

.map-preview .map-container {
    width: 100%;
    height: 100%;
}

.map-preview p {
    margin: 0;
    padding: 5px;
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 12px;
}

.map-preview .map-label {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.8);
    padding: 5px;
    font-size: 12px;
}

.map-preview-loading {
    width: 400px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    margin: 0 auto;
    border: 1px solid #ccc;
}
.map-preview {
    transition: opacity 0.5s ease-in-out;
}

.map-preview.fade-in {
    opacity: 1;
}
.featured-data-insights {
    width: 28vw;
    height: clamp(250px, 30.8vh, 350px);
    border-radius: 6px;
    background: #ECECEC;
    padding: clamp(0.75rem, 1vw, 1rem);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}
.user-dashboard__featured-data-insights-section {
    flex-shrink: 0;
    margin-left: -9vw;
}

.featured-data-insights__title {
    color: #000;
    text-align: center;
    font-family: "Aneba Neue", sans-serif;
    font-size: clamp(14px, 1vw, 16px);
    font-style: normal;
    font-weight: 500;
    margin: 0;
    padding: 0 0.5rem;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: clamp(18px, 2vh, 20px);
    line-height: clamp(18px, 2vh, 20px);
}

.featured-data-insights__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem 0;
    flex: 1;
    min-height: 0;
}

.featured-data-insights__image-container {
    position: relative;
    width: 17.5vw;
    height: 15vh;
    overflow: hidden;
    border-radius: 4px;
}

.featured-data-insights__animated-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.featured-data-insights__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.featured-data-insights__arrow {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: clamp(20px, 1.5vw, 24px);
    height: clamp(20px, 1.5vw, 24px);
}

.featured-data-insights__arrow svg {
    fill: #919191;
    width: clamp(8px, 0.7vw, 10px);
    height: clamp(14px, 1vw, 16.251px);
}

.featured-data-insights__arrow:hover svg {
    fill: #1352E2;
}

.featured-data-insights__read-more {
    width: 25.67vw;
    height: clamp(32px, 3.7vh, 40px);
    border-radius: 6px;
    border: 2px solid #D9D9D9;
    background: #D9D9D9;
    color: #000;
    text-align: center;
    font-family: "Aneba Neue", sans-serif;
    font-size: clamp(12px, 0.9vw, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: auto;
    padding: 0;
}

.featured-data-insights__read-more:hover {
    border: 2px solid #1352E2;
    background: #1352E2;
    color: white;
}

.featured-data-insights__image-container.single-image {
    width: 90%;
    margin: 0 auto;
}

/* Loading state */
.featured-data-insights.loading {
    justify-content: center;
    align-items: center;
    font-family: "Aneba Neue", sans-serif;
    color: #919191;
    font-size: clamp(12px, 0.9vw, 14px);
}

/* Responsive Breakpoints */
@media screen and (max-width: 1024px) {
    .featured-data-insights {
        width: 32vw;
        /* display: flex; */
    }

    .featured-data-insights__image-container {
        width: 20vw;
    }

    .featured-data-insights__read-more {
        width: 28vw;
    }
    .user-dashboard__featured-data-insights-section {
        flex-shrink: 0;
        margin-left: auto;
    }
}

@media screen and (max-width: 768px) {
    .featured-data-insights {
        width: 100%;
        height: auto;
        min-height: clamp(250px, 30vh, 300px);
        padding: 1rem;
    }

    .featured-data-insights__title {
        font-size: 16px;
        height: auto;
        line-height: 1.2;
        white-space: normal;
        margin-bottom: 1rem;
    }

    .featured-data-insights__content {
        margin: 1rem 0;
    }

    .featured-data-insights__image-container {
        width: calc(100% - 60px); /* Accounting for arrows */
        height: clamp(150px, 25vh, 200px);
    }

    .featured-data-insights__arrow {
        width: 24px;
        height: 24px;
        padding: 0 0.5rem;
    }

    .featured-data-insights__arrow svg {
        width: 12px;
        height: 18px;
    }

    .featured-data-insights__read-more {
        width: 100%;
        height: 40px;
        font-size: 14px;
        margin-top: 1rem;
    }

    .featured-data-insights__image-container.single-image {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .featured-data-insights {
        min-height: clamp(200px, 25vh, 250px);
        padding: 0.75rem;
    }

    .featured-data-insights__title {
        font-size: 14px;
        margin-bottom: 0.75rem;
    }

    .featured-data-insights__image-container {
        height: clamp(120px, 20vh, 150px);
    }

    .featured-data-insights__arrow {
        width: 20px;
        height: 20px;
        padding: 0 0.25rem;
    }

    .featured-data-insights__arrow svg {
        width: 10px;
        height: 16px;
    }

    .featured-data-insights__read-more {
        height: 36px;
        font-size: 13px;
        margin-top: 0.75rem;
    }
}
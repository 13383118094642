@import url('../assets/AnebaNeue/aneba-neue.css');

.statistics-tab {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  left: 8vw;
  top: 50%;
  transform: translateX(0%) translateY(-47%);
  width: 28.6vw;
  height: 82.2vh;
  background-color: #ECECEC;
  z-index: 1;
  padding: 0.93vh 0.00vw;
  overflow-y: auto;
  font-family: 'Aneba Neue', sans-serif;
  transition: ease-in-out;
  border-radius: 6px;
}

.close-button {
  background: none;
  border: none;
  font-size: clamp(16px, 1.8vw, 32px);
  cursor: pointer;
  color: black;
  margin-right: -4vw;
  margin-top: 0vh;
  transition: transform 0.3s ease-in-out;
}

.close-button:hover {
  transform: scale(1.2);
}

.close-button:active {
  transform: scale(0.8);
}

.statistics-tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.93vh;
}

.tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5vh;
  position: relative;
  width: 100%;
  margin-top: 1vh;
}

.tab-header-left {
  display: flex;
  align-items: center;
}

.tab-header-left div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.current-selection {
  font-size: clamp(12px, 0.9vw, 20px);
  font-style: italic;
  margin-top: 0.2vh;
  margin-left: 0.4vw;
}

.add-all-button {
  background-color: #D3D3D3;
  border: none;
  color: #000000;
  font-size: clamp(14px, 1vw, 24px);
  cursor: pointer;
  padding: 0.46vh 0.52vw;
  border-radius: 0.37vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-header-right {
  display: flex;
  align-items: center;
  gap: 1vw;
  /* Ensure spacing between buttons */
  position: absolute;
  top: 50%;
  right: 1vw;
  transform: translateY(-50%);
}

/* .close-button {
    background: none;
    border: none;
    font-size: 3.5vh;
    cursor: pointer;
    color: black;
    margin-right: -3vw;
    margin-top: -4vh;
} */

.tab-header .header-icon {
  height: 2.778vh;
  margin-right: 0.42vw;
}

.tab-header h2 {
  margin: 0;
  font-weight: 500;
  margin-left: .2vw;
  font-size: clamp(17px, 1.1vw, 24px);
}

.content-box {
  width: 25vw;
  min-height: 27.5vh;
  background-color: #ffffff;
  border-radius: .9vh;
  overflow: hidden;
  margin-bottom: 0.93vh;
  position: relative;
  /* Add this */
}

.box-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #000000;
  padding: 0.93vh;
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  padding-left: 12px;
}

.box-header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}

.box-header-title {
  font-weight: 600;
  margin-bottom: 0.2vh;
}

.box-location-label {
  font-size: 11px;
  font-weight: 400;
  color: #000;
  padding-bottom: 2px;
  margin-bottom: 4px;
  font-style: italic;
  padding-left: 4px;
}

.box-header-buttons {
  display: flex;
  gap: 0.52vw;
  align-items: flex-start;
  /* Align buttons to top */
  margin-left: 1vw;
}

.plus-button,
.minus-button {
  background-color: #D3D3D3;
  border: none;
  color: #000000;
  cursor: pointer;
  border-radius: 0.37vh;
  width: 24px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: all 0.1s ease-in-out;
}

.plus-button svg,
.minus-button svg {
  width: 10px;
  height: 10px;
  /* transition: color 0.3s ease-in-out; */
}

.plus-button:not(.added):hover {
  background-color: #1352E2;
  color: #FFFFFF;
}

.minus-button:hover {
  background-color: #E20516;
  color: #FFFFFF;
}

.plus-button.added {
  background-color: #82BE20;
  color: #FFFFFF;
}

.box-content {
  padding: 0.93vh;
  height: calc(27.5vh - 2 * 0.93vh);
  /* Subtract the padding from the min-height */
  overflow: auto;
  /* Enable scrolling if content exceeds the fixed height */
}

/* Add this new style for flexible height */
.content-box.flexible-height {
  height: auto;
}

.content-box.flexible-height .box-content {
  height: auto;
  min-height: calc(27.5vh - 2 * 0.93vh);
  max-height: none;
}

.summary-box,
.bar-graph-box {
  margin-bottom: 0.93vh;
}

.pie-chart-box .box-content,
.bar-graph-box .box-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  padding: 0.46vh 0.52vw;
  margin-bottom: 0.46vh;
  border-radius: 3px;
}

.summary-label {
  font-size: clamp(11px, 0.85vw, 18px);
}

.summary-value {
  /* font-weight: normal; */
  font-size: clamp(11px, 0.85vw, 18px);
}

.recent-sales-list .summary-item {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: 0.25vh 0.6vw;
  margin-bottom: 0.2vh;
  border-radius: 3px;
  min-height: 0;
}

.recent-sales-list .summary-label {
  font-size: clamp(9px, 0.65vw, 13px);
  font-weight: 500;
  margin-bottom: 0;
  color: #000000;
  line-height: 1.1;
}

.recent-sales-list .summary-value {
  font-size: clamp(8px, 0.6vw, 12px);
  display: flex;
  gap: 0.3vw;
  align-items: center;
  line-height: 1.1;
}

.sale-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 0.1vh;
}

.sale-primary-info {
  display: flex;
  gap: 0.3vw;
  align-items: center;
  font-size: clamp(8px, 0.7vw, 12px);
}

.sale-secondary-info {
  display: flex;
  gap: 0.3vw;
  align-items: center;
  color: #666666;
  font-size: clamp(8px, 0.6vw, 12px);
}

.content-area {
  width: calc(100% - 29.6vw);
  /* Adjusted to not overlap with .statistics-tab */
  height: 90vh;
  /* Adjusted for full-page height within view */
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #ECECEC;
  position: absolute;
  right: 0;
  top: 5vh;
  padding: 1rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  aspect-ratio: 210 / 297;
}

.page {
  background-color: #ffffff;
  border-radius: 0.37vh;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-bottom: 1rem;
  overflow-y: auto;
  /* Allows scrolling within each page */
  min-height: 70vh;
}

.add-page-button,
.remove-page-button {
  padding: 0.5rem 1rem;
  background-color: #000000;
  color: #ffffff;
  font-size: clamp(14px, 1vw, 24px);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: fixed;
  bottom: 2vh;
}

.remove-page-button {
  right: 10vw;
  /* Adjust as necessary for placement */
}

.add-page-button {
  right: 2vw;
}

.add-remove-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  padding: 10px;
  border-radius: 5px;
}

/* Add this to your existing CSS */
.saved-presets-content-box {
  background-color: transparent !important;
  box-shadow: none !important;
}

.saved-presets-content-box .box-content {
  background-color: transparent !important;
  height: auto !important;
  min-height: 0 !important;
}

.saved-presets-tab .MuiTextField-root {
  background-color: white;
}

.saved-presets-tab .MuiListItem-root:not(.MuiListItem-button) {
  padding-left: 0;
  padding-right: 0;
}

.content-box-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.content-box-outline {
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  pointer-events: none;
}

.expand-button {
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: auto;
}

.expanded-content {
  margin-top: 20px;
}

.analysis-textarea {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
}
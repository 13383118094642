@import url('../assets/AnebaNeue/aneba-neue.css');

.blog-page {
    display: flex;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    font-family: 'Aneba Neue', sans-serif;
    overflow: hidden;
}

.blog-page .blog-sidebar {
    width: 16.9vw;  /* Standardized with BlogPost sidebar */
    flex-shrink: 0;
    margin-left: 3vw;  /* Standardized with BlogPost sidebar */
    padding: 2vh 2vw 0vh 2vw;
    background-color: #ECECEC;
    height: 81.8vh;  /* Standardized with BlogPost sidebar */
    margin-top: 10vh;
    overflow-y: auto;
    border-radius: 6px;
}

.blog-page .blog-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
}

.blog-page .blog-header-container {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
    padding-top: 10vh;
    padding-bottom: 3vh;
}

.blog-page .blog-header {
    font-size: 4.17vh;
    margin: 0;
    margin-left: 5.2vw;
    font-weight: 500;
}

.blog-page .blog-grid-container {
    flex: 1;
    overflow-y: auto;
    padding-top: 1vh;
}

.blog-page .blog-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1vw 0.5vw;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 3vh;
}

.blog-page .blog-post-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 17vw;
}

.blog-page .blog-post-card img {
    width: 16vw;
    height: 19vh;
    object-fit: cover;
    border-radius: 6px;
}

.blog-page .post-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.blog-page .post-category {
    font-size: 1.5vh;
    margin-top: 0;
}

.blog-page .post-title {
    font-size: 2.22vh;
    margin-bottom: 0;
    width: 79%;
    text-align: left;
    font-weight: 500;
    margin-top: 0;
}

.blog-page .post-date {
    color: black;
    font-size: 1.48vh;
    text-align: right;
    margin-top: 0;
    width: 100%;
    margin-bottom: 0;
}

.blog-page .post-excerpt {
    font-size: 1.48vh;
    margin-bottom: 1.5vh;
    width: 100%;
    text-align: left;
    margin-top: 0vh;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blog-page .read-more {
    background-color: #ECECEC;
    color: black;
    border: none;
    width: 7.4vw;
    height: 3.7vh;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1.48vh;
    align-self: center;
    transition: all 0.3s;
}

.blog-page .read-more:hover {
    background-color: #1352e2;
    color: white;
}

.blog-page .blog-sidebar h2 {
    font-size: 2.7vh;
    font-weight: 600;
    margin-top: 0;
}

.blog-page .blog-sidebar h3 {
    font-size: 2.2vh;
    font-weight: 600;
}

.blog-page .blog-sidebar p {
    font-size: 1.7vh;
    margin-top: -10px;
}

.blog-page .blog-sidebar ul {
    font-size: 1.7vh;
    list-style-type: none;
    padding-left: 0;
    margin-top: -10px;
}

.blog-page .blog-sidebar ul li {
    position: relative;
    padding-left: 1.5em;
    margin-bottom: 0.5em;
}

.blog-page .blog-sidebar ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0.8em;
    height: 0.8em;
    background-color: #D9D9D9;
    border-radius: 50%;
}


.blog-page .blog-search-input {
    width: calc(100% - 1vw);
    padding: 1vh 1vw;
    margin-bottom: 2vh;
    background-color: transparent;
    border: 1px solid #000;
    border-radius: 0.56vh;
    color: #000;
    font-family: 'Aneba Neue', sans-serif;
    outline: none;
}

.blog-page .blog-year-range {
    display: flex;
    align-items: center;
    gap: 1vw;
    margin-left: 0.5vw;
}

.blog-page .blog-year-range input {
    padding: 0.5vh 1vw;
    background-color: transparent;
    border: 1px solid #000;
    border-radius: 6px;
    color: #000;
    font-family: 'Aneba Neue', sans-serif;
    font-size: 1.5vh;
    outline: none;
    height: 3vh;
    text-align: center;
}

.blog-page .blog-year-range span {
    font-size: 1.48vh;
    color: #000;
}


.blog-post-link {
    text-decoration: none;
    color: inherit;
}

.blog-post-link:hover {
    text-decoration: none;
}

.blog-page .blog-sidebar .blog-search-form {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 2vh;
    margin-top: 4vh;
}

.blog-page .blog-sidebar .blog-search-input {
    width: 100%;
    padding: 0.5vh 2.5vw 0.5vh 1vw;
    background-color: transparent;
    border: 1px solid #000;
    border-radius: 0.56vh;
    color: #000;
    font-family: 'Aneba Neue', sans-serif;
    outline: none;
    height: 3vh;
}
.button-centered {
    display: flex;
    align-self: center;
}
.blog-page .blog-sidebar .blog-search-button {
    position: absolute;
    right: 0.5vw;
    top: 50%;
    transform: translateX(-10%) translateY(-88%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #000;
    font-size: 0.833vw;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    height: 2.5vh;
    width: 2.5vh;
}

.blog-page .blog-sidebar .blog-search-button svg {
    font-size: 1.5em;
}

.blog-page .blog-year-range input[type="number"] {
    -moz-appearance: textfield;
}

.blog-page .blog-year-range input[type="number"]::-webkit-outer-spin-button,
.blog-page .blog-year-range input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media screen and (max-width: 768px) {
    .blog-page {
        flex-direction: column;
    }

    .blog-page .blog-sidebar {
        display: none;
    }

    .blog-page .blog-content {
        width: 100%;
        margin-left: 0;
    }

    .blog-page .blog-grid {
        grid-template-columns: 1fr;
        padding: 0 5vw;
    }

    .blog-page .blog-post-card {
        width: 100%;
        margin-bottom: 4vh;
    }

    .blog-page .blog-post-card img {
        width: 100%;
        height: 25vh;
    }

    .blog-page .read-more {
        width: 30vw;
    }

    .blog-page .blog-header {
        font-size: 3.5vh;
        text-align: center;
        margin-left: 0;
    }

    .blog-page .post-title {
        width: 100%;
        font-size: 2.5vh;
    }

    .blog-page .post-excerpt {
        font-size: 1.8vh;
    }
}
.footer {
  background-color: #D9D9D9;
  color: #000;
  padding: 4vh 1vw;
  font-family: 'Aneba Neue', sans-serif;
  z-index: 3;
  /* Ensure it is below the testimonial section */
  overflow: visible;
  width: 100%;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Align items to the left */
  justify-content: space-between;
  margin: 4vh 2vw;
  height: 100%;
}

.footer-links {
  display: flex;
  justify-content: wrap;
  width: 100%;
  margin-bottom: 2rem;
}

.footer-section {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  /* Add margin-right to create spacing between sections */
}

.footer-section a {
  color: #000;
  text-decoration: none;
  font-size: 0.9rem;
  margin: 0.2rem 0;
  font-weight: 500;
}

.footer-section a:hover {
  text-decoration: underline;
}

.footer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: auto;
  padding-top: 5rem;
}

.footer-logo img {
  height: 30px;
}
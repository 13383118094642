/* ResearchTutorial.css */
.research-tutorial-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2000;
}

.research-tutorial-container {
    width: 31.25vw;
    height: 70.28vh;
    background-color: #ECECEC;
    border-radius: 6px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.research-tutorial-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 1rem;
}

.research-tutorial-images-wrapper {
    width: 100%;
    overflow: hidden;
    margin-bottom: 1rem;
}

.research-tutorial-images {
    display: flex;
    gap: 0.5rem;
    width: 100%;
}

.research-tutorial-image-container {
    width: 7.7vw;
    height: 33.43vh;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}

.research-tutorial-image-container::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 35%;
    background: linear-gradient(to bottom,
            rgba(236, 236, 236, 0) 0%,
            rgba(236, 236, 236, 1) 100%);
    pointer-events: none;
}

.research-tutorial-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.research-tutorial-title {
    color: #000;
    text-align: center;
    font-family: "Aneba Neue";
    font-size: clamp(12px, 1.34vw, 18px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0.5rem 0;
}

.research-tutorial-text {
    color: #000;
    text-align: center;
    font-family: "Aneba Neue";
    font-size: clamp(9px, 0.89vw, 12px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 80%;
    margin: 0.25rem 0;
}

.research-tutorial-text--padded {
    padding-top: 0.75rem;
}

.research-tutorial-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
}

.research-tutorial-button {
    width: 5.9vw;
    height: 3.7vh;
    border-radius: 6px;
    font-family: "Aneba Neue";
    font-size: clamp(12px, 1.19vw, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    cursor: pointer;
}

.research-tutorial-button--start {
    background: #D9D9D9;
    color: #1352E2;
}

.research-tutorial-button--tour {
    background: #1352E2;
    color: white;
}

.research-tutorial-bubble {
    position: fixed;
    background: #ECECEC;
    border-radius: 6px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    width: 14.38vw;
    height: 11.93vh;
    padding: 1rem;
    box-sizing: border-box;
    z-index: 2002;
}

.research-tutorial-bubble-text {
    color: #000;
    text-align: center;
    font-family: "Aneba Neue";
    font-size: clamp(9px, 0.89vw, 12px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
}

.research-tutorial-exit-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: clamp(8px, 0.589vw, 11.314px);
    height: clamp(8px, 0.589vw, 11.314px);
    cursor: pointer;
}

.research-tutorial-next-icon {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    width: clamp(8px, 0.589vw, 11.314px);
    height: clamp(8px, 0.589vw, 11.314px);
    cursor: pointer;
    transform: rotate(-90deg);
}

/* Arrow styles for different bubble positions */
.research-tutorial-bubble-arrow {
    position: absolute;
    width: 0;
    height: 0;
}

.research-tutorial-bubble--searchField .research-tutorial-bubble-arrow,
.research-tutorial-bubble--secondSearchField .research-tutorial-bubble-arrow,
.research-tutorial-bubble--quickDateFilter .research-tutorial-bubble-arrow {
    top: -10px;
    left: 20px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ECECEC;
}

.research-tutorial-bubble--dropdown .research-tutorial-bubble-arrow {
    right: -10px;
    /* Change from right to left */
    top: 40px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #ECECEC;
    /* Change from border-left to border-right */
}

.research-tutorial-bubble--researchCards .research-tutorial-bubble-arrow {
    left: -10px;
    /* Changed from right */
    top: 20px;
    /* Changed from bottom */
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #ECECEC;
    /* Changed from border-top */
}

.research-tutorial-bubble--graphicBox .research-tutorial-bubble-arrow,
.research-tutorial-bubble--dataTypeSelector .research-tutorial-bubble-arrow {
    left: -10px;
    top: 20px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #ECECEC;
}

.research-tutorial-bubble--clearSelections .research-tutorial-bubble-arrow {
    right: -10px;
    top: 20px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #ECECEC;
}

/* Add highlight area for focused elements */
.research-tutorial-highlight-area {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 2001;
}

.research-tutorial-highlight-area::after {
    content: '';
    position: absolute;
    top: var(--target-top, 0);
    left: var(--target-left, 0);
    width: var(--target-width, 0);
    height: var(--target-height, 0);
    background: rgba(255, 255, 255, 0.1);
    /* Slight highlight */
    border-radius: 4px;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5),
        0 0 15px rgba(19, 82, 226, 0.3);
    /* Blue glow */
    pointer-events: none;
}
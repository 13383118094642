@import url('../assets/AnebaNeue/aneba-neue.css');

/* Mobile Menu - New Addition */
.mobile-menu-button {
    display: none;
    position: fixed;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1001;
    width: auto;
    background: transparent;
}

.mobile-menu-button button {
    background: #ECECEC;
    border: none;
    border-radius: 4px;
    padding: 0.2rem .6rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #1352e2;
    font-weight: 600;
    font-family: 'Aneba Neue', sans-serif;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
}

.mobile-dropdown {
    display: none;
    position: fixed;
    top: calc(1rem + 40px);
    left: 50%;
    transform: translateX(-50%);
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 300px;
    z-index: 1000;
    font-family: 'Aneba Neue', sans-serif;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.mobile-dropdown .profile-picture-container {
    padding: 1rem;
    border-bottom: 1px solid #ECECEC;
}

.mobile-dropdown .tabs {
    padding: 0.5rem;
}

.mobile-dropdown .tab {
    padding: 0.75rem 1rem;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.mobile-dropdown .tab:hover {
    background-color: #f5f5f5;
}

.mobile-menu-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    transition: opacity 0.3s ease;
}

.mobile-user-name {
    margin-left: 1rem;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Aneba Neue', sans-serif;
}

/* Main Container */
.account-container {
    display: flex;
    min-height: calc(100vh - 64px);
    margin-top: 64px;
    font-family: 'Aneba Neue', sans-serif;
    position: relative;
}

/* Sidebar */
.sidebar {
    width: 15vw;
    height: 84.1vh;
    padding: 20px;
    position: fixed;
    left: 2.6vw;
    margin-top: 2.12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ECECEC;
    z-index: 1;
    box-sizing: border-box;
    transition: transform 0.3s ease-in-out;
    border-radius: 6px;
}

.sidebar.mobile-open {
    transform: translateX(0);
}

.logo-container {
    text-align: center;
    margin-bottom: 20px;
}

.tabs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.tab {
    display: flex;
    align-items: center;
    padding: 4px;
    cursor: pointer;
    white-space: nowrap;
    width: 100%;
    color: #000;
    font-size: clamp(14px, 1.2vw, 24px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: 'Aneba Neue', sans-serif;
    transition: background-color 0.3s ease;
}

.tab img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.content {
    flex: 1;
    margin-left: calc(12vw);
    margin-top: 15vh;
}

.form-container {
    max-width: 30vw;
    margin: 0 auto;
    margin-top: -100px;
    margin-left: 10vw;
}

.form-group {
    margin-bottom: 10px;
    font-weight: 600;
    position: relative;
    font-family: 'Aneba Neue', sans-serif;
}

.form-group label {
    font-size: clamp(14px, 0.95vw, 18px);
    margin-bottom: 5px;
    display: block;
    font-weight: 500;
    font-family: 'Aneba Neue', sans-serif;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="tel"],
.form-group input[type="url"] {
    width: 100%;
    padding: 10px;
    font-size: clamp(14px, 1vw, 20px);
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'Aneba Neue', sans-serif;
    transition: border-color 0.3s ease;
}

.form-group input[type="text"]:focus,
.form-group input[type="email"]:focus,
.form-group input[type="tel"]:focus,
.form-group input[type="url"]:focus {
    outline: none;
    border-color: #1352e2;
}

.form-group input[type="text"]:disabled,
.form-group input[type="email"]:disabled,
.form-group input[type="tel"]:disabled,
.form-group input[type="url"]:disabled {
    background-color: #ECECEC;
    cursor: not-allowed;
    border: none;
}

.form-group input::placeholder {
    font-family: 'Aneba Neue', sans-serif;
}

.form-group .error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
    font-family: 'Aneba Neue', sans-serif;
}

.edit-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #1352e2;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    font-family: 'Aneba Neue', sans-serif;
    transition: all 0.3s ease, margin 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    transform: translateX(0);
}

.edit-button:hover {
    background-color: #0f42a8;
}

/* When in edit mode (form has both cancel and save buttons) */
.button-group .edit-button {
    margin: 0vw 1vw;  /* Add margin when in the button group */
}

/* Cancel button styles to match */
.cancel-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #ccc;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Aneba Neue', sans-serif;
    transition: all 0.3s ease;
    margin: 0vw 1vw 0vw 0vw;
}

.cancel-button:hover {
    background-color: #999;
}

/* Style for disabled state */
.edit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.general-heading {
    display: flex;
    font-size: clamp(24px, 2vw, 38px);
    font-weight: 500;
    font-family: 'Aneba Neue', sans-serif;
    align-items: center;
    align-content: center;
}

.general-icon {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
}

.button-group {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.cancel-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #ccc;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Aneba Neue', sans-serif;
    transition: all 0.3s ease;
}

.cancel-button:hover {
    background-color: #999;
}

.profile-picture-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-picture-wrapper {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
}

.profile-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.default-profile-picture {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #1352e2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: #fff;
    font-family: 'Aneba Neue', sans-serif;
}

.edit-profile-button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #1352e2;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Aneba Neue', sans-serif;
    transition: all 0.3s ease;
}

.edit-profile-button:hover {
    background-color: #0f42a8;
}

.delete-profile-picture {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ff0000;
    color: #fff;
    border: none;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.user-name {
    text-align: center;
    font-size: clamp(12px, 1.1vw, 20px);
    font-weight: 500;
    font-family: 'Aneba Neue', sans-serif;
}

.edit-profile-picture-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.edit-profile-picture-input {
    margin-bottom: 10px;
    font-family: 'Aneba Neue', sans-serif;
}

.cancel-edit-profile-picture {
    padding: 5px 10px;
    background-color: #ccc;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Aneba Neue', sans-serif;
    transition: all 0.3s ease;
}

.cancel-edit-profile-picture:hover {
    background-color: #999;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
    .form-container {
        max-width: 40vw;
        margin-left: 5vw;
    }
}

@media screen and (max-width: 768px) {
    .mobile-menu-button {
        display: flex;
    }

    .mobile-menu-overlay {
        left: 250px;
        top: 56px;
    }
    
    .mobile-dropdown {
        display: block;
        opacity: 0;
        transform: translate(-50%, -10px);
        pointer-events: none;
    }

    .mobile-dropdown.mobile-open {
        opacity: 1;
        transform: translate(-50%, 0);
        pointer-events: all;
    }

    .sidebar {
        display: none;
    }

    .sidebar.mobile-open {
        left: 0;
        transform: translateX(0);
    }

    .content {
        margin-left: 0;
        margin-top: 76px;
        width: 100%;
    }

    .form-container {
        margin-top: 20px;
    }

    .general-heading {
        font-size: clamp(20px, 5vw, 24px);
        padding: 0 20px;
    }

    .form-group label {
        font-size: 16px;
    }

    .form-group input[type="text"],
    .form-group input[type="email"],
    .form-group input[type="tel"],
    .form-group input[type="url"] {
        font-size: 16px;
    }

    .default-profile-picture {
        width: 60px;
        height: 60px;
        font-size: 24px;
    }

    .user-name {
        font-size: 16px;
    }

    .mobile-menu-overlay:not(.sidebar.mobile-open) {
        left: 0;
    }
}

@media screen and (max-width: 480px) {
    .form-container {
        max-width: 95%;
        padding: 0 10px;
    }

    .button-group {
        flex-direction: column;
        gap: 10px;
    }

    .edit-button,
    .cancel-button {
        width: 100%;
    }

    .profile-picture-wrapper {
        width: 80px;
        height: 80px;
    }

    .general-heading {
        font-size: clamp(18px, 4vw, 20px);
    }

    .mobile-menu-button {
        padding: 0.75rem;
    }
}
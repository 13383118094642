@import url('../assets/AnebaNeue/aneba-neue.css');

.feedback-bubble {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    transition: all 0.3s ease;
    font-family: 'Aneba Neue', sans-serif;
}

.feedback-bubble__button {
    background-color: #1352e2;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.feedback-bubble__button:hover {
    background-color: #357ABD;
}

.feedback-bubble__content {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 300px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.feedback-bubble__close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
}

.feedback-bubble__title {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 20px;
    color: #333;
}

.feedback-bubble__text {
    margin-bottom: 20px;
    font-size: 14px;
    color: #666;
    line-height: 1.5;
}

.feedback-bubble__survey-link {
    display: inline-block;
    background-color: #1352e2;
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.feedback-bubble__survey-link:hover {
    background-color: #357ABD;
}

/* Mobile styles */
@media (max-width: 480px) {
    .feedback-bubble__content {
        width: calc(100% - 40px);
        max-height: calc(100vh - 40px);
        overflow-y: auto;
    }
}
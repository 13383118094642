.subscription-plans-wrapper {
    margin-left: 5vw;
    margin-top: -15vh;
    padding: 20px;
}

.subscription-plans-heading {
    display: flex;
    align-items: center;
    font-size: 2rem;
    margin-bottom: 20px;
}

.subscription-plans-icon {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
}

.subscription-plans-container {
    display: flex;
    justify-content: center;
}

.subscription-plan-item {
    width: 16.88vw;
    height: 64.3vh;
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.subscription-plan-item-header {
    background-color: #000;
    color: #fff;
    text-align: center;
    height: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.subscription-plan-item-header h3 {
    margin: 0;
    font-size: 1rem;
}

.subscription-plan-item-image-container {
    height: 33vh;
    overflow: hidden;
}

.subscription-plan-item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.subscription-plan-item-content {
    height: 29.6vh;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.subscription-plan-item-price {
    font-size: 2rem;
    margin: 5px 0;
}

.subscription-plan-item-button {
    background-color: #e6e6e6;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.subscription-plan-item-features {
    text-align: left;
    font-size: 0.8rem;
    /* Add this line */
    padding: 5px;
    /* Add this line */
    margin-top: 10px;
    /* Add this line */
}

.subscription-plan-item-features h4 {
    margin: 0 0 5px 0;
    /* Modify this line */
    padding-left: 5px;
    /* Add this line */
}

.subscription-plan-item-features ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.subscription-plan-item-features li {
    margin-bottom: 3px;
    position: relative;
    padding-left: 25px;
    /* Increase this value */
    padding-right: 5px;
    /* Add this line */
    border: 1px solid black;
    /* Add this line */
}

.subscription-plan-item-features li.included::before {
    content: '✓';
    color: #4169e1;
    position: absolute;
    left: 5px;
    /* Modify this value */
}
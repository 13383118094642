.blog-post-page {
    display: flex;
    width: 100vw;
    height: calc(100vh - 80px);
    /* Subtract navbar height */
    margin: 0;
    padding: 0;
    font-family: 'Aneba Neue', sans-serif;
    overflow: hidden;
    margin-top: 80px;
    /* Add margin-top equal to navbar height */
}

.blog-sidebar {
    display: block;
}

.blog-post-content {
    flex: 1;
    padding: 10vh 10vw 5vh;
    overflow-y: auto;
    max-width: 800px;
    margin: 0 auto;
}

.blog-post-content img {
    width: 80%;
    height: auto;
    /* max-height: 40vh; */
    object-fit: cover;
    margin-bottom: 2vh;
    border-radius: 6px;
}

.blog-post-content .post-date {
    color: black;
    font-size: 1.48vh;
    text-align: right;
    margin-top: 0.5vh;
    margin-bottom: 1vh;
}

.blog-post-content h1 {
    font-size: 3vh;
    margin-bottom: 2vh;
    width: 100%;
    text-align: left;
    font-weight: 500;
}

.blog-post-content h2 {
    font-size: 2.5vh;
    margin-bottom: 2vh;
    width: 100%;
    text-align: left;
    font-weight: 500;
}

.blog-post-content h3 {
    font-size: 2vh;
    margin-bottom: 2vh;
    width: 100%;
    text-align: left;
    font-weight: 500;
}

.blog-post-content .post-body {
    font-size: 1.8vh;
    line-height: 1.6;
    margin-bottom: 2vh;
}

.blog-post-content .post-category {
    font-size: 1.48vh;
    color: #1352e2;
    margin-bottom: 2vh;
}

.blog-post-content .post-author {
    font-size: 1.48vh;
    color: #666;
    margin-bottom: 1vh;
}

.delete-post-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    display: inline-block;
}

.delete-post-button:hover {
    background-color: #c0392b;
}

.delete-post-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(231, 76, 60, 0.5);
}

.delete-post-button:active {
    transform: translateY(1px);
}

/* Positioning the delete button */
.blog-post-content {
    position: relative;
}

.delete-post-button {
    position: absolute;
    top: 11vh;
    right: 20px;
}

.back-to-blogs-button {
    display: none;
    text-decoration: none;
    color: #1352e2;
    font-size: 1.8vh;
    padding: 2vh 5vw;
    font-weight: 500;
    background: white;
    border-bottom: 1px solid #ECECEC;
}

.back-to-blogs-button:hover {
    background: #f5f5f5;
}

/* Responsive design for smaller screens */
@media screen and (max-width: 768px) {
    .blog-post-page {
        flex-direction: column;
    }

    .blog-sidebar {
        display: none;
    }

    .back-to-blogs-button {
        display: block;
    }

    .blog-post-content {
        padding: 5vh 5vw;
        max-width: 100%;
    }

    .blog-post-content img {
        width: 100%;
        margin-bottom: 3vh;
    }

    .blog-post-content h1 {
        font-size: 2.8vh;
        margin-top: 2vh;
    }

    .blog-post-content .post-body {
        font-size: 1.6vh;
    }

    .blog-post-content .post-category {
        font-size: 1.4vh;
    }

    .blog-post-content .post-date,
    .blog-post-content .post-author {
        font-size: 1.3vh;
    }
}
@import url('../assets/AnebaNeue/aneba-neue.css');

:root {
  --base-width: 1344;
  --base-height: 767;
  --spacing-unit: calc(1vw * (1344 / var(--base-width)));
  --height-unit: calc(1vh * (767 / var(--base-height)));

  /* Add new positioning variables */
  --statistics-tab-width: 31vw;
  /* Width of the statistics tab */
  --main-sidebar-width: 6.2vw;
  /* Width of the main sidebar */
  --gap-width: 1vw;
  /* Gap between elements */
}

/* Main container for the MarketModePage component */
.market-mode-page-container {
  position: relative;
  top: 10.5vh;
  width: 780px;
  height: 644px;
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;
  font-family: 'Aneba Neue', sans-serif;
  left: 42vw !important;
}

.navbar.market-page {
  background-color: #ECECEC;
  padding: 0.93vh 1.04vw;
  font-family: 'Aneba Neue', sans-serif;
  min-height: 30px;
}

.market-mode-page {
  width: 100%;
  min-height: 1056px;
  background-color: #ffffff;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
}

/* Add a new class for the wrapper */
.market-mode-page-wrapper {
  border: 1.5px solid #ececec;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
}

/* Remove margin from the last wrapper to avoid extra space at the bottom */
.market-mode-page-wrapper:last-child {
  margin-bottom: 0;
}


.market-mode-subtype-section {
  position: relative;
  padding: 16px;
}

.market-mode-subtype-section-wrapper {
  margin-bottom: 23px;
}

.market-mode-subtype-header {
  position: relative;
  display: flex;
  align-items: center;
  width: 91%;
  margin-left: auto;
  margin-right: auto;
}

.market-mode-subtype-header .subtype-icon {
  height: .9rem;
  margin-right: .5rem;
  margin-top: 6px;
}

.market-mode-subtype-header::after,
.market-mode-subtype-header::before {
  display: none;
}

.market-mode-subtype-header h2 {
  margin: 0;
  font-size: .9rem;
  margin-top: 10px;
  font-weight: 600;
}

.market-mode-page-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  margin-top: -9px;
  margin-bottom: -2.3rem;
  padding: 15px 10px 15px 13px;
  row-gap: 4vh;
  column-gap: 0vh;
}

.market-mode-content-box-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 20px;
}

.market-mode-content-box {
  background-color: #ffffff;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
  width: 90%;
  margin: 0 17px;
  border: 1px solid #ececec;
  box-sizing: border-box;
  height: 100%;
}

.market-mode-box-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #000000;
  padding: 7px 34px 7px 14px;
  color: #ffffff;
  font-size: 12px;
  position: relative;
  font-weight: 600;
}


.market-mode-box-title {
  font-weight: 600;
  margin-bottom: 2px;
  line-height: 1.2;
}

.market-mode-box-header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  padding-right: 25px;
}

.market-mode-box-location {
  font-size: 0.75em;
  color: #cccccc;
  font-weight: 400;
  margin-top: 2px;
  line-height: 1.2;
}

.market-mode-box-content-location {
  font-size: 11px;
  font-weight: 400;
  color: #000;
  padding-bottom: 2px;
  margin-bottom: 4px;
  margin-top: 2px;
  font-style: italic;
  padding-left: 2px;
}

.market-mode-remove-button {
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-image: url("../assets/Group 93.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.market-mode-content-box:hover .market-mode-remove-button {
  opacity: 1;
}


.market-mode-subtype-remove-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-image: url("../assets/Group 93.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.market-mode-subtype-header:hover .market-mode-subtype-remove-button {
  opacity: 1;
}

.market-mode-box-content {
  padding: 4px 12px 12px 12px;
  border-radius: 6px;
  height: 180px;
  overflow: auto;
}

.content-box-outline {
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  pointer-events: none;
}

.expand-button {
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: auto;
}

.expanded-content {
  margin-top: 20px;
}

.analysis-textarea {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
}

.banner-container {
  width: 100%;
  background-color: #1352e2;
  height: 4.5rem;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: relative;
}

.banner-content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.banner-edit-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 27px;
  /* Fixed height that will be preserved */
}

.banner-edit-button {
  background: transparent;
  color: #A1BAF3;
  border: none;
  cursor: pointer;
  font-size: 11px;
  transition: color 0.2s;
}

.banner-edit-button:hover {
  color: white;
}

.banner-text-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-top: 0px;
  /* Add padding equal to button container height */
}

.banner-text-company {
  padding-left: 20px;
  font-weight: 600;
  font-size: 16px;
  color: white;
}

.banner-text-information {
  padding-left: 27px;
  font-size: 11px;
  color: white;
  display: flex;
  align-items: center;
}

.banner-separator {
  margin: 0 1em;
  color: white;
}

/* Print styles */
@media print {
  .banner-edit-button-container {
    visibility: hidden;
    height: 27px;
    /* Maintain height even when hidden */
  }

  .banner-container {
    position: relative;
  }

  .banner-content {
    position: absolute;
    top: 0;
  }

  .banner-text-wrapper {
    position: absolute;
    padding-top: 27px;
    /* Maintain spacing in print */
    width: 100%;
  }
}

.logo-icon {
  position: absolute;
  right: 13px;
  width: 50px;
  height: auto;
  z-index: 1000;
}

.white-logo {
  filter: brightness(0) invert(1);
}

.fade-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(75 * (var(--height-unit) / 767) * 1px);
  background: linear-gradient(to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%);
  z-index: 1000;
}

.fade-and-print-container {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  pointer-events: none;
  z-index: 1100;
  width: auto;
}

.print-icon {
  position: absolute;
  width: 2rem;
  height: 2rem;
  background-image: url("../assets/print-icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  pointer-events: auto;
  z-index: 1200;
  bottom: 1.5rem;
  right: 1rem;
  background-color: white;
}

@media print {
  .market-mode-page-wrapper {
    border: none;
    margin: 0;
    padding: 0;
    width: 816px;
    /* Standard US Letter width at 96 DPI */
    min-height: 1056px;
    /* Standard US Letter height at 96 DPI */
  }

  .market-mode-page {
    page-break-inside: avoid;
    margin: 0;
    padding: 0;
  }
}

/* Add specific styles for preview generation */
.market-mode-page-wrapper.preview-mode {
  border: none;
  margin: 0;
  padding: 0;
}

.print-icon.printing {
  pointer-events: none;
}

/* Optional loading animation */
@keyframes printing-pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.print-icon.printing {
  animation: printing-pulse 1s infinite;
}

/* Hide the hidden container from any possible view */
#print-preview-container {
  position: fixed !important;
  top: -9999px !important;
  left: -9999px !important;
  visibility: hidden !important;
  pointer-events: none !important;
  opacity: 0 !important;
}

.print-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.print-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: calc(20 * (var(--spacing-unit) / 1344) * 1px);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  max-width: calc(80 * var(--spacing-unit));
  max-height: calc(80 * var(--height-unit));
  overflow-y: auto;
}

.print-popup button {
  margin: 5px;
  padding: 10px 15px;
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.blur-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.print-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.file-name-input {
  margin-bottom: 20px;
}

.file-name-input input {
  border: none;
  background-color: #d9d9d9;
  color: #000;
  min-height: 30px;
  font-family: "Aneba Neue", sans-serif;
  width: 20vw;
  padding: 0.5vh 1vw 0.5vh 1vw;
  border-radius: 0.56vh;
  outline: none;
}

.pages-preview {
  margin:20px;
}

.preview-container {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  padding-bottom: 10px;
}

.page-previews {
  display: flex;
  gap: 10px;
  overflow-x: hidden;
}


.scrollable-content {
  height: 100%;
  overflow-y: auto;
  position: relative;
}

.page-preview {
  flex: 0 0 auto;
  width: 100px;
  height: 141px;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
}

.page-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.save-options {
  display: flex;
  justify-content: center;
}

.save-options button {
  padding: 10px 20px;
  background-color: #ececec;
  color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-options button:hover {
  background-color: #1352e2;
  color: white;
}

.preview-container::-webkit-scrollbar {
  height: 8px;
}

.preview-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.preview-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.preview-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.print-icon {
  transition: transform 0.2s ease-in-out;
}

.print-icon:hover {
  transform: scale(1.1);
}

.enlarged-preview {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  max-width: 477.2px;
  max-height: 700px;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: initial;
  display: flex;
  flex-direction: column;
  z-index: 1003;
}

.enlarged-preview .image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.enlarged-preview img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.enlarged-preview .close-button-preview {
  position: absolute;
  top: -15px;
  right: -20px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000;
}

.debug-info {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 12px;
  color: #888;
}

.file-name-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1002;
  width: 300px;
}

.file-name-popup .file-name-input {
  display: flex;
  justify-content: center;
}

.file-name-popup .save-options {
  display: flex;
  justify-content: center;
}

.file-name-popup .save-options button {
  margin-left: 10px;
}

.subtype-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  pointer-events: none;
  z-index: 1000;
}

.expand-button-container {
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1001;
  pointer-events: auto;
}

.expand-button-subtype-overlay {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  border: 0px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: auto;
  padding: 0;
}

.expanded-content-subtype-overlay {
  padding: 10px;
  background-color: #f0f0f0;
  border-top: 1px solid #ccc;
  margin-top: 15px;
  pointer-events: auto;
}

.subtype-outline {
  border: 1px solid black;
  border-radius: 5px;
  box-sizing: border-box;
  transition: width 0.3s ease;
  overflow: visible;
}

.footer-market-page {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 12px;
  color: #000000;
}



/* Update media queries to maintain positioning */
@media (min-width: 1345px) {
  :root {
    --base-width: 1344;
    --base-height: 767;
  }

  .market-mode-page-container {
    max-width: calc(58 * var(--spacing-unit));
    left: calc(var(--statistics-tab-width) + var(--main-sidebar-width) + var(--gap-width));
    margin: 0;
    position: relative;
    /* Ensure container has proper positioning context */
  }

  .fade-and-print-container {
    right: calc(4 * var(--spacing-unit));
    /* Adjust position for larger screens */
  }
}

/* Add specific handling for smaller screens if needed */
@media (max-width: 1344px) {
  :root {
    --base-width: 1344;
    --base-height: 767;
  }

  .market-mode-page-container {
    /* Maintain proportional positioning at smaller screens */
    left: calc(var(--statistics-tab-width) + var(--main-sidebar-width) + var(--gap-width));
  }
}
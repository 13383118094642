@import url("../assets/AnebaNeue/aneba-neue.css");

.about-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.about-section {
  flex: 1;
  display: flex;
  align-items: stretch;
  padding: 0;
  color: #000;
  min-height: 100vh;
  font-family: "Aneba Neue", sans-serif;
  box-sizing: border-box;
  overflow: hidden;
}

.about-content {
  width: 100%;
  padding: 6vh 4vw 1.85vh 1.56vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  box-sizing: border-box;
  background-color: #ECECEC;
}

.content-wrapper {
  max-width: 100%;
}

.about-content h2 {
  color: #000;
  margin-bottom: 0.5rem;
  line-height: 1;
  font-size: 4.5rem;
  font-style: normal;
  font-weight: 550;
  letter-spacing: -0.065vw;
  margin-block-start: 0.75em;
  margin-block-end: 0.33em;
  margin-left: 1rem;
}

.about-content h3 {
  color: #000;
  margin-bottom: 0.5rem;
  line-height: 1;
  font-size: 3rem;
  font-style: normal;
  font-weight: 550;
  letter-spacing: -0.065vw;
  margin-block-start: 0.5em;
  margin-block-end: 0.33em;
  margin-left: 1rem;
}

.about-content p {
  color: #000;
  font-size: 0.75rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  margin-left: 1rem;
}

/* Responsive Styles */
@media screen and (max-width: 1200px) {
  .about-content p,
  .about-content h3,
  .about-content h2 {
    margin-left: 0rem;
  }

  .about-content {
    width: 100%;
    padding: 4vh 6vw 1.85vh;
  }

  .about-section {
    flex-direction: column;
  }
}

@media screen and (max-width: 900px) {
  .about-section {
    flex-direction: column;
  }

  .about-content p,
  .about-content h3,
  .about-content h2 {
    margin-left: 0rem;
  }

  .about-content {
    width: 100%;
    padding: 4vh 6vw 1.85vh;
  }

  .about-content h2 {
    font-size: 3.5rem;
  }

  .about-content h3 {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 600px) {
  .about-content {
    padding: 4vh 4vw 1.85vh;
  }

  .about-content h2 {
    font-size: 2.5rem;
  }

  .about-content h3 {
    font-size: 2rem;
  }

  .about-content p {
    font-size: 0.75rem;
  }
}
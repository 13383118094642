.visit-other-products {
    width: 14.7vw;
    height: clamp(250px, 30.8vh, 350px);
    border-radius: 6px;
    background: #ECECEC;
    padding: clamp(0.75rem, 1vw, 1rem);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.visit-other-products__image-container {
    width: 11.6vw;
    height: 23vh;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
}

.visit-other-products__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.visit-other-products__image-container::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 35%;
    background: linear-gradient(to bottom,
            rgba(236, 236, 236, 0) 0%,
            rgba(236, 236, 236, 1) 100%);
    pointer-events: none;
}

.visit-other-products__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.visit-other-products__description {
    color: #000;
    font-family: "Aneba Neue", sans-serif;
    font-size: clamp(12px, 0.9vw, 13px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
    flex-grow: 1;
    text-align: center;
}

.mobile-only-text {
    font-size: 11px;
    opacity: 0.8;
    margin-left: 4px;
}

/* Responsive Breakpoints */
@media screen and (max-width: 1024px) {
    .visit-other-products {
        width: 18vw;
    }

    .visit-other-products__image-container {
        width: 15vw;
    }
}

@media screen and (max-width: 768px) {
    .visit-other-products {
        width: 100%;
        height: auto;
        min-height: clamp(200px, 25vh, 250px);
        flex-direction: row;
        gap: 1rem;
        padding: clamp(0.75rem, 1.5vw, 1rem);
    }

    .visit-other-products__image-container {
        width: clamp(120px, 30%, 150px);
        height: auto;
        aspect-ratio: 1/1;
        margin: 0;
    }

    .visit-other-products__content {
        flex: 1;
        justify-content: space-between;
        padding: 0.5rem 0;
    }

    .visit-other-products__description {
        font-size: 14px;
        text-align: left;
        margin-top: 0;
    }

    .visit-other-products__button {
        width: 100%;
        height: 40px;
        margin-top: 1rem;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .visit-other-products__image-container::after {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .visit-other-products {
        min-height: clamp(150px, 20vh, 200px);
        padding: 0.75rem;
    }

    .visit-other-products__image-container {
        width: clamp(100px, 25%, 120px);
    }

    .visit-other-products__description {
        font-size: 13px;
    }

    .visit-other-products__button {
        height: 36px;
        font-size: 13px;
    }
}
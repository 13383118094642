/* Base button styles */
.btn {
    border: none;
    cursor: pointer;
    font-family: "Aneba Neue", sans-serif;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Button 1 - Product Card Button */
.btn--1 {
    border-radius: 6px;
    background: #D9D9D9;
    color: #000;
    font-weight: 400;
    margin-top: auto;
}

/* Size variants for Button 1 */
.btn--1.size--s {
    width: 8vw;
    height: clamp(28px, 3vh, 32px);
    font-size: clamp(11px, 0.85vw, 13px);
}

.btn--1.size--m {
    width: 12.5vw;
    height: clamp(30px, 3.7vh, 40px);
    font-size: clamp(12px, 0.95vw, 14px);
}

.btn--1.size--l {
    width: 16vw;
    height: clamp(36px, 4.2vh, 48px);
    font-size: clamp(14px, 1.1vw, 16px);
}

.btn--1:not(:disabled):hover {
    border: 2px solid #1352E2;
    background: #1352E2;
    color: white;
}

/* Button 2 - Try/Dashboard Button */
.btn--2 {
    background-color: #1352E2;
    color: white;
    border-radius: 4px;
    font-weight: 500;
}

/* Size variants for Button 2 */
.btn--2.size--s {
    width: 5vw;
    height: 3vh;
    font-size: 0.8rem;
}

.btn--2.size--m {
    width: 10vw;
    height: 4vh;
    font-size: 0.9rem;
}

.btn--2.size--l {
    width: 100%;
    height: 5vh;
    font-size: 1rem;
}

.btn--2:hover {
    background-color: #0f42b8;
}

/* Disabled state */
.btn--disabled {
    opacity: 0.6;
    cursor: not-allowed;
    background: #B8B8B8;
}

.mobile-only-text {
    font-size: 11px;
    opacity: 0.8;
    margin-left: 4px;
}

/* Responsive Breakpoints */
@media screen and (max-width: 1200px) {
    /* Button 1 sizes */
    .btn--1.size--s { width: 10vw; }
    .btn--1.size--m { width: 15vw; }
    .btn--1.size--l { width: 20vw; }

    /* Button 2 sizes */
    .btn--2 {
        width: auto;
        padding: 6px 12px;
    }
}

@media screen and (max-width: 768px) {
    /* Button 1 sizes */
    .btn--1.size--s,
    .btn--1.size--m,
    .btn--1.size--l {
        width: 100%;
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn--1.size--s { height: 36px; font-size: 13px; }
    .btn--1.size--m { height: 40px; font-size: 14px; }
    .btn--1.size--l { height: 44px; font-size: 15px; }
}

@media screen and (max-width: 600px) {
    /* Button 2 sizes */
    .btn--2.size--s { width: 100px; height: 28px; margin: 3vh 0; }
    .btn--2.size--m { width: 125px; height: 30px; margin: 4vh 0; }
    .btn--2.size--l { width: 150px; height: 32px; margin: 5vh 0; }
}
.realanalytica-notfound {
    min-height: 100vh;
    width: 100vw;
    background-color: #ECECEC;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-family: "Aneba Neue", sans-serif;
}

.realanalytica-notfound__container {
    width: 100%;
    max-width: 1920px;
    padding: 2rem;
}

.realanalytica-notfound__content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
}

.realanalytica-notfound__text-block {
    max-width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.realanalytica-notfound__title {
    color: #000;
    text-align: center;
    font-family: "Aneba Neue";
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 70px;
    margin-bottom: 0.5rem;
    width: 100%;
}

.realanalytica-notfound__subtitle {
    color: #919191;
    text-align: center;
    font-family: "Aneba Neue";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: -0.48px;
    margin-bottom: 2rem;
    max-width: 600px;
}

.realanalytica-notfound__button {
    background: #1352E2;
    border: none;
    border-radius: 6px;
    padding: 0.75rem 1.5rem;
    color: white;
    font-family: "Aneba Neue", sans-serif;
    font-size: 1rem;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.realanalytica-notfound__button:hover {
    background: #0f42a8;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Responsive scaling */
@media screen and (max-width: 1920px) {
    .realanalytica-notfound__title {
        font-size: clamp(32px, 3.33vw, 64px);
        line-height: clamp(38px, 3.65vw, 70px);
    }

    .realanalytica-notfound__subtitle {
        font-size: clamp(16px, 1.25vw, 24px);
        line-height: clamp(24px, 1.82vw, 35px);
    }
}

/* Mobile responsive styles */
@media screen and (max-width: 768px) {
    .realanalytica-notfound {
        align-items: flex-start;
    }

    .realanalytica-notfound__container {
        padding: 6rem 2rem 2rem;
    }

    .realanalytica-notfound__text-block {
        padding: 0 1rem;
    }

    .realanalytica-notfound__button {
        font-size: 0.875rem;
        padding: 0.625rem 1.25rem;
    }
}

/* Small mobile devices */
@media screen and (max-width: 480px) {
    .realanalytica-notfound__container {
        padding: 5rem 1rem 1rem;
    }
}
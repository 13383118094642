/* SavedPresetsTab.css */

.saved-presets-tab {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.saved-presets-search {
    position: relative;
    width: 24.7vw;
}

.saved-presets-search-icon {
    position: absolute;
    /* right: 10px; */
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 600;
    pointer-events: none;
}

.saved-presets-search-input {
    border-radius: 6px;
    background: #D9D9D9;
    width: 100%;
    height: 3.7vh;
    border: none;
    padding: 0 10px 0 32px;
    font-size: 0.85em;
    color: #000;
    font-family: "Aneba Neue";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.saved-presets-search-input::placeholder {
    color: #888;
}

.saved-presets-search-input:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.saved-presets-sections {
    width: 24.7vw;
    margin-top: 2vh;
}

.saved-presets-section {
    width: 100%;
}

.saved-presets-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000000;
    padding: 0.93vh;
    color: #ffffff;
    font-weight: 600;
    font-size: 0.85em;
    cursor: pointer;
    height: 2vh;
    border-radius: 6px 6px 0px 0px;

}

.saved-presets-list {
    max-height: 150px;
    overflow-y: auto;
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin-top: 1vh;
}

.saved-presets-list-item {
    padding: 0.5vh 1vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-left: 1vw;
    font-size: 14px;
}

.saved-presets-list-item-text {
    cursor: pointer;
    flex-grow: 1;
}

.saved-presets-delete-icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    position: absolute;
    right: 1vh;
}

.saved-presets-list-item:hover .saved-presets-delete-icon {
    opacity: 1;
}
/* User Dashboard styles */
.user-dashboard {
    max-width: 80vw;
    margin: 0 auto;
    margin-left: 6.7vw;
    margin-top: -100px;
}

.user-dashboard__heading {
    display: flex;
    align-items: center;
    font-size: 2.1rem;
    margin-bottom: 1px;
    font-weight: 500;
}

.user-dashboard__icon {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
}

.user-dashboard__left-column {
    display: flex;
    flex-direction: column;
}

.user-dashboard__watchlist-section {
    flex-grow: 0;
    flex-shrink: 0;
}

.user-dashboard__section-heading {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.user-dashboard__watchlist-container {
    display: flex;
    gap: 0.78vw;
}

/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    z-index: 1000;
}

.modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 2rem;
    border-radius: 5px;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    z-index: 1001;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.modal-header h2 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 500;
}

.modal-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: #666;
    padding: 0.5rem;
}

.modal-close-btn:hover {
    color: #000;
    transition: all 0.2s ease;
}

.modal-content-watchlist {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}

.modal-search {
    position: relative;
    margin-bottom: 2rem;
}

.modal-search input {
    width: 100%;
    padding: 0.5rem .75rem;
    border: none;
    background: #ECECEC;
    border-radius: 5px;
    font-size: 1rem;
}

.modal-search-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
}

/* Watchlist Component styles */
.watchlist {
    width: 18vw;
    height: 34.6vh;
    border-radius: 5px;
    background: #ECECEC;
    padding: 1rem;
    box-sizing: border-box;
    flex-shrink: 0;
    position: relative;
    display: flex;
    flex-direction: column;
}

.watchlist--preview {
    width: 14vw;
    height: 25vh;
    padding: 0.75rem;
    background: white;
    max-width: 220px;
}

.watchlist--preview:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Enhanced shadow on hover */
    transition: box-shadow 0.2s ease;
}

.watchlist__header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.watchlist--preview .watchlist__header {
    margin-bottom: 0.5rem;
}

.watchlist__color-indicator {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 0.5rem;
}

.watchlist--preview .watchlist__color-indicator {
    width: 8px;
    height: 8px;
}

.watchlist__neighborhood {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 500;
}

.watchlist__neighborhood--preview {
    font-size: 0.875rem;
}

.watchlist__quick-facts {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    /* Add space between quick fact items */
}

.quick-fact-section {
    flex: none;
    /* Remove flex growing */
}

.quick-fact-label {
    color: #919191;
    font-family: "Aneba Neue", sans-serif;
    font-size: .75rem;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 4px;
    margin-top: 5px;
}

.quick-fact-section:nth-child(2) .quick-fact-label {
    margin-top: 2px;
}

.quick-fact-label--preview {
    font-size: 10px;
    margin: 4px;
}

.quick-fact-value {
    font-family: "Aneba Neue", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.preview-label {
    font-size: 10px;
    margin: 4px 0 2px 0;
}

.watchlist__actions {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    display: flex;
    gap: 0.5rem;
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.watchlist__actions--enabled {
    visibility: visible;
}

.watchlist:hover .watchlist__actions--enabled {
    opacity: 1;
    visibility: visible;
}

.watchlist__action-btn {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #666;
    transition: all 0.2s ease;
}
.valuation-label{
    font-size: 1rem;
}

.watchlist__action-btn:hover {
    background-color: #e0e0e0;
    color: #000;
}

.watchlist__preview-remove {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(50%, -50%);
    /* This moves the center point to the corner */
}

.watchlist__preview-remove--visible {
    opacity: 1;
}

.watchlist__preview-remove-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
}

/* Modal-specific watchlist styles */
.modal-watchlist-preview {
    height: 25vh;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    overflow-x: auto;
    padding: 1rem;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    background: #ECECEC;
    border-radius: 5px;
}

.modal-current-watchlist h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    margin: 0 0 1rem 0;
}

.modal-actions {
    gap: 1rem;
    margin-top: 1rem;
}

.modal-add-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    background: #1352E2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s ease;
}

.modal-add-btn:hover {
    background: #0f42a8;
    transition: all 0.2s ease;
}

.modal-save-btn {
    /* width: 100%; */
    padding: .75rem 1.5rem;
    background: #ECECEC;
    border: none;
    border-radius: 5px;
    margin-top: 1rem;
    cursor: pointer;
    font-size: rem;
    transition: background-color 0.2s ease;
    flex: 1 1;
}

.modal-save-btn:hover {
    background-color: #DADADA;
    transition: all 0.2s ease;
}

.watchlist__last-updated {
    font-size: 0.5rem;
    color: #666;
    text-align: left;
    margin-top: 0.5rem;
    font-style: italic;
}

/* Responsive Breakpoints */
@media screen and (max-width: 1024px) {
    .user-dashboard {
        max-width: 90vw;
    }

    .user-dashboard__left-column {
        width: 40vw;
    }

    .watchlist {
        width: 22vw;
    }

    .watchlist--preview {
        width: clamp(160px, 16vw, 200px);
    }

    .modal-container-watchlist {
        max-width: 60vw;
    }
    .watchlist__last-updated {
        margin-top: auto;
    }
    .modal-current-watchlist h3 {
        margin: 0 0 0 0;
    }
}

@media screen and (max-width: 768px) {
    .user-dashboard {
        max-width: 100%;
        margin: 76px auto 0;
        padding: 20px;
    }

    .user-dashboard__content {
        flex-direction: column;
        gap: 2rem;
        padding: 1rem;
    }

    .user-dashboard__left-column {
        width: 100%;
    }

    .watchlist {
        width: 100%;
        height: auto;
        min-height: clamp(250px, 30vh, 300px);
    }

    .watchlist--preview {
        width: 100%;
        height: auto;
        min-height: clamp(200px, 25vh, 250px);
    }

    .watchlist__actions {
        opacity: 1;
        visibility: visible;
    }

    .modal-container-watchlist {
        width: 95%;
        height: 90vh;
        max-width: none;
        padding: 1rem;
        overflow-y: auto;
    }

    .valuation-graph {
        max-width: 100%;
        height: clamp(150px, 15vh, 200px);
    }

    .user-dashboard__watchlist-container {
        flex-direction: column;
        width: 100%;
        gap: 1rem;
    }
}

@media screen and (max-width: 480px) {
    .user-dashboard {
        margin: 0.5rem;
        padding: 10px;
    }

    .user-dashboard__heading {
        font-size: clamp(18px, 4vw, 20px);
        padding: 0 10px;
    }

    .watchlist {
        min-height: clamp(200px, 25vh, 250px);
        padding: 0.75rem;
    }

    .watchlist--preview {
        min-height: clamp(180px, 20vh, 200px);
    }

    .modal-container-watchlist {
        padding: 0.75rem;
    }

    .modal-header h2 {
        font-size: 1.25rem;
    }

    .modal-search input,
    .modal-save-btn {
        font-size: 16px;
    }

    .valuation-label,
    .valuation-value,
    .quick-fact-label,
    .quick-fact-value {
        font-size: .75rem;
    }
}
.research-page-card {
    font-family: "Aneba Neue", sans-serif;
    width: 20vw;
    /* Increased from 20vw */
    height: 67vh;
    border: 1px solid transparent;
    border-radius: .56vh;
    padding: 1vh .75vw;
    margin: .5vw;
    /* Changed from 8px to 8px 0 to remove horizontal margin */
    background-color: white;
    display: flex;
    flex-direction: column;
}

.research-page-card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1vh;
}

.research-page-card-title h2 {
    font-size: 1vw;
    font-weight: 400;
    margin: 0;
}

.color-indicator {
    width: 5.78vw;
    height: .75vh;
    border-radius: .56vh;
}

.research-page-card-map {
    height: 25vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 1vh;
}

.research-page-card-map .map-preview {
    width: 100%;
    height: 100%;
    margin: 0;
}

.mapboxgl-ctrl-bottom-left {
    /* bottom: 0; */
    /* This line is commented out as requested */
    left: 0;
    top: 0;
}

a.mapboxgl-ctrl-logo {
    height: 12px;
}

.research-page-card-map .map-preview .map-container {
    border-radius: .56vh;
    height: 100%;
}

.research-page-graphic-box {
    flex: 1;
    overflow-y: auto;
    border: 1px solid #e0e0e0;
    border-radius: .56vh;
}

.expanded-view {
    padding: 1vw;
    padding-top: 0;
}
.expanded-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
    padding-left: 0;
    padding-top: 12px;
    border-bottom: 1px solid #e0e0e0;
}
.toggle-arrow {
    width: 12px;
    height: 12px;
    transition: transform 0.3s ease;
}
.toggle-arrow.rotated {
    transform: rotate(180deg);
}

.expanded-header h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}

.expanded-view h3 {
    cursor: pointer;
    padding-bottom: 10px;
    font-size: 1.75vh;
    font-weight: 400;
}

.expanded-view h3:hover {
    color: #0056b3;
}

.data-type-item {
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
}

.data-type-item:last-child {
    border-bottom: none;
}

.data-type-item h3 {
    margin: 0;
    font-size: 16px;
    color: #333;
}

.data-type-item p {
    margin: 5px 0 0;
    font-size: 12px;
    color: #666;
}

.data-type-item.selected {
    background-color: #f0f0f0;
}

/* Ensure charts and graphs fit within the graphic box */
.research-page-graphic-box>div {
    /* width: 100%; */
    height: auto;
    margin-bottom: 1vh;
}

/* Adjust font sizes for better fit */
.research-page-graphic-box h4 {
    font-size: 1vh;
    margin-bottom: 0.5vh;
    font-weight: 100;
}

.research-page-graphic-box p {
    font-size: .8vh;
    margin-bottom: 0.5vh;
    font-weight: 100;
}

/* Ensure Recharts responsive container fits */
.research-page-graphic-box .recharts-responsive-container {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 16 / 9;
}

.valuation-overview {
    width: 95%;
    height: auto;
    margin-bottom: 1vh;
}

.valuation-overview .MuiChart-root {
    width: 95% !important;
    height: auto !important;
}
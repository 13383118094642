/* src/components/DashboardSection.css */
.left-aligned-section {
    width: 100%;
    font-family: 'Aneba Neue', sans-serif;
    background-color: white;
}

.left-aligned-content {
    margin-left: 16vw;
    margin-bottom: 2vh;
    padding: 0 2rem;
}

.left-aligned-content h2 {
    color: #000;
    text-align: left;
    font-family: "Aneba Neue";
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.05vw;
}

.left-aligned-content p {
    text-align: left;
    font-size: .9rem;
    line-height: 1.2;
    font-weight: 400;
    margin-bottom: 7vh;
}

@media screen and (max-width: 1200px) {
    .left-aligned-content {
        margin-left: 8vw;
    }
    
    .left-aligned-content h2 {
        font-size: 2.8rem;
    }
}

@media screen and (max-width: 900px) {
    .left-aligned-content {
        margin-left: 4vw;
    }
    
    .left-aligned-content h2 {
        font-size: 2.4rem;
    }
    
    .left-aligned-content p {
        font-size: 0.85rem;
    }
}

@media screen and (max-width: 600px) {
    .left-aligned-content {
        margin-left: 2rem;
        padding: 0 1rem;
    }
    
    .left-aligned-content h2 {
        font-size: 2rem;
    }
    
    .left-aligned-content p {
        font-size: 0.8rem;
        margin-bottom: 4vh;
    }
}

@media screen and (max-width: 400px) {
    .left-aligned-content {
        margin-left: 1rem;
        padding: 0 0.5rem;
    }
    
    .left-aligned-content h2 {
        font-size: 1.8rem;
    }
    
    .left-aligned-content p {
        font-size: 0.75rem;
        margin-bottom: 3vh;
    }
}
.data-type-selector {
    display: flex;
    flex-direction: column;
}

.data-type-item {
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    position: relative;
    border-bottom: 1px solid #e0e0e0;
}

.data-type-item:last-child {
    border-bottom: none;
}

.data-type-item:hover {
    background-color: #f0f0f0;
}

.data-type-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.toggle-arrow-data-selector {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;
    min-width: 16px;
    opacity: 0;
    visibility: hidden;
}

/* Show arrow on hover */
.data-type-item:hover .toggle-arrow-data-selector {
    opacity: 1;
    visibility: visible;
}

.toggle-arrow-data-selector.rotated {
    transform: translateY(-50%) rotate(180deg);
}

.data-type-item h3 {
    margin: 0;
    font-size: 1.75vh;
    color: #333;
    font-weight: 400;
}

.data-type-item p {
    margin: 5px 20px 0 0;
    font-size: 12px;
    color: #666;
}
.marketing-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    max-height: 100vh;
    padding-bottom: 2.6rem;
    padding-right: 2.5rem;
    font-family: "Aneba Neue";
}

.marketing-content {
    text-align: left;
    padding-left: 17vw;
    align-items: flex-start;
    width: 100%;
}

.marketing-content h2 {
    color: #000;
    text-align: left;
    font-family: "Aneba Neue";
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.08rem;
    margin-bottom: 0.4rem;
    line-height: 1.1;
}

.marketing-content p {
    font-size: 0.9rem;
    line-height: 1.5;
    font-weight: 400;
    margin-top: 0;
    text-align: left;
}

.marketing-points {
    display: flex;
    justify-content: center;
    gap: 3rem;
    width: 100%;
    margin-top: 1.6rem;
}

.marketing-point {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ECECEC;
    padding: 1.5rem;
    flex: 1 1;
    max-width: 16rem;
    align-content: center;
}

.marketing-point img {
    width: 8.8rem;
    height: 8.8rem;
    object-fit: contain;
}

.marketing-point h3 {
    font-size: 1.35rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    height: 1.6rem;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
}

.marketing-point p {
    font-size: 0.8rem;
    line-height: 1.5;
    font-weight: 400;
    margin-top: 0;
    flex-grow: 1;
    text-align: left;
    width: 100%;
}

@media (max-width: 1200px) {
    .marketing-content {
        padding-left: 8vw;
    }
    
    .marketing-content h2 {
        font-size: 2.8rem;
    }
    
    .marketing-points {
        padding-left: 8vw;
        padding-right: 8vw;
        gap: 2rem;
    }
}
@media (max-width: 900px) {
    .marketing-content {
        padding-left: 2rem;
    }

    .marketing-content h2 {
        font-size: 2.4rem;
    }

    .marketing-points {
        overflow-x: auto;
        flex-wrap: nowrap;
        justify-content: flex-start;
        padding: 0 2rem;  /* Changed to padding shorthand */
        margin: 1.5rem 0; /* Added vertical margin */
        gap: 1.5rem;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    .marketing-points::-webkit-scrollbar {
        display: none;
    }

    .marketing-point {
        flex: 0 0 280px;
        max-width: none;
        margin-right: 0.5rem; /* Added margin to ensure spacing at the end */
    }

    /* Add padding to the last marketing point to ensure proper spacing at the end */
    .marketing-point:last-child {
        margin-right: 2rem;
    }
}

@media (max-width: 600px) {
    .marketing-section {
        padding-right: 0;
        padding-bottom: 1.5rem;
    }

    .marketing-content {
        padding-left: 1.5rem;  /* Increased from 1rem */
        padding-right: 1.5rem; /* Increased from 1rem */
    }

    .marketing-content h2 {
        font-size: 2rem;
    }

    .marketing-content p {
        font-size: 0.85rem;
    }

    .marketing-points {
        padding: 0 1.5rem;  /* Increased from 1rem */
        margin: 1rem 0;
        gap: 1rem;
    }

    .marketing-point {
        padding: 1rem;
        flex: 0 0 260px;
        margin-right: 0.5rem;
    }

    .marketing-point:last-child {
        margin-right: 1.5rem; /* Matches the padding of the container */
    }
}